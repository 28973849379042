<template>
  <div class="next">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left van-haptics-feedback">
          <i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow" @click="back"></i>
        </div>
        <div class="van-nav-bar__title van-ellipsis"></div>
      </div>
    </div>
    <div class="co">
      <div class="van-image" style="width: 120px; height: 120px;"><img
          :src="info.img_url ? info.img_url[0] : ''" class="van-image__img" style="object-fit: cover;">
      </div>
      <div class="info"><p>{{ info.xuanfei_name }} <span
          style="margin-left: 10px; color: rgb(250, 154, 10); font-size: 13px; font-weight: 600;">{{ info.uid }}</span>
      </p>
        <div class="rz"><img alt="" src="/img/beauty/rz.png"></div>
        <div class="jk"><p>{{ $t('choose.deposit_paid') }}</p></div>
      </div>
    </div>
    <h1 class="tpzs">{{ $t('choose.picture_display') }}</h1>
    <swiper :options="bannerSwiperOption">
      <swiper-slide v-for="(image, index) in info.img_url" :key="index">
        <img :src="image" alt="Slide Image">
      </swiper-slide>
    </swiper>
    <div class="sm">
      <div class="lef">
        <h1 class="title" style="padding: 10px 0px;">{{ $t('choose.girl_profile') }}</h1>
        <div class="tips">
          <span v-for="(tag,index) in info.tag_name" :key="index">{{ tag }}</span>
        </div>
        <p>{{ $t('choose.height') }}: {{ info.height }}cm {{ $t('choose.bust') }}：{{ info.bust }}</p>
        <p class="sf">{{ $t('choose.experience_satisfaction') }}：<span
            v-for="i in Array.from({ length: Math.floor(info.rating) })" :key="i"></span>
        </p>
        <p>{{ $t('choose.serve_the_city') }}：{{ info.address }}</p>
      </div>
      <!--      <div class="right">-->
      <!--        <img alt="" src="img/zan.c28032c8.png">-->
      <!--        <span>4569讚</span>-->
      <!--      </div>-->
    </div>
    <div class="foot"><h1 style="padding-bottom: 0px;">{{ $t('choose.live_action_film') }}</h1>
      <div style="padding-top: 10px;">
        <div ref="dplayer"></div>
      </div>
    </div>
    <div class="jianjie">
      <h2>{{ $t('choose.instructions_for_casual_sex') }}</h2>
      <p>
        {{ $t('choose.instructions_for_casual_sex_value') }}
      </p>
    </div>
    <div class="foots">
      <a @click="showPopover= true">{{ $t('choose.consult_customer_service') }}</a>
    </div>
    <van-popup v-model="showPopover" class="van-dialog">
      <div class="van-dialog__header">{{ $t('choose.return_call') }}</div>
      <div class="van-dialog__content">
        <p style="padding: 20px 20px 0px; font-size: 16px; color: rgb(125, 118, 239);">
          {{ $t('choose.contact_customer_service_please') }}</p>
      </div>
      <div class="van-hairline--top van-dialog__footer">
        <button class="van-button van-button--default van-button--large van-dialog__cancel" type="button"
                @click="cancel">
          <div class="van-button__content">
            <span class="van-button__text">{{ $t('setting.cancel') }}</span>
          </div>
        </button>
        <button class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left"
                type="button"
                @click="confirm">
          <div class="van-button__content"><span class="van-button__text">{{ $t('setting.confirm') }}</span></div>
        </button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import DPlayer from 'dplayer';

export default {
  data() {
    return {
      bannerSwiperOption: {
        grabCursor: true,
        spaceBetween: 10,
        slidesPerView: 2,
        speed: 800,
      },
      info: {},
      dp: null,
      isPlaying: false, // 用于跟踪播放状态
      showPopover: false,
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    cancel() {
      this.showPopover = false
    },
    confirm() {
      window.open(localStorage.getItem('kefu'), '_blank');
      this.showPopover = false
    },
  },
  mounted() {
    this.$http({
      method: 'get',
      url: 'xuanfeidata',
      data: {id: this.$route.query.id}
    }).then(res => {
      this.info = res.data
      this.dp = new DPlayer({
        container: this.$refs.dplayer,
        video: {
          url: this.info.video_url,
          type: 'auto',
          autoplay: false, // 不自动播放
          muted: true, // 视频静音
          playsinline: true, // 内联播放
          preload: 'auto' // 预加载视频
        },
      });
      this.dp.on('play', () => {
        this.isPlaying = true;
      });

      this.dp.on('pause', () => {
        this.isPlaying = false;
      });
      this.dp.on('loadedmetadata', () => {
        this.dp.play();

      });
      document.addEventListener('touchstart', () => {
        this.dp.play();
      }, {once: true})
      document.addEventListener('touchmove', () => {
        this.dp.play();
      }, {once: true});
    })

  },
  beforeDestroy() {
    if (this.dp) {
      this.dp.destroy();
    }
  },
}
</script>


<style lang="less" scoped>

.next {
  padding-bottom: 4rem;
  min-height: 100%;
  background: url(/img/beauty/header_bgc.png) no-repeat center -35PX / 100% auto, url(/img/beauty/bgc.png) no-repeat top / 100% auto;

  .van-dialog {
    border-radius: 4px;
    background: url(/img/mine/withdraw_dialog_bg.png) no-repeat 50% / 100% auto;

    .van-dialog__header {
      background: linear-gradient(133deg, #e64588, #f22678);
      padding: 10px;
      color: #fff;
      font-size: 14px;
    }

    .van-button--default {
      background: none;
      line-height: 48px;
    }

  }

  .swiper-slide {
    height: 11.33333rem;
    overflow: hidden;
    position: relative;
    border-radius: .33333rem;
  }

  .van-hairline--bottom:after {
    border-width: 0;
  }

  .van-nav-bar {
    z-index: 999;
    background: none;
    position: relative;
    line-height: var(--van-line-height-lg);
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .van-nav-bar__content {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--van-nav-bar-height);

      .van-nav-bar__left, .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 var(--van-padding-md);
        font-size: var(--van-font-size-md);
      }

      .van-nav-bar__left {
        left: 0;

        .van-icon {
          color: #fff !important;
          font-size: 20px;

        }

        .van-icon-arrow-left:before {
          font-size: 20px;
          color: #fff;

        }
      }
    }

  }
  @media (min-width: 450px) {
    .co {
      margin: 8% auto 0 !important;
     }
  }
  @media (min-width: 480px) {
    .co {
      margin: 10% auto 0 !important;
    }
  }
  .co {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin: 6% auto 0;

    .van-image {
      border-radius: 10px;
      overflow: hidden;

      .van-image__img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 1rem;
      flex: 1;

      & > P {
        color: #fff;
        font-size: 1.06667rem;
      }

      .rz {
        height: 1.66667rem;
        margin-top: .66667rem;

        img {
          height: 100%;
          margin-right: .33333rem;
        }
      }

      .jk {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;

        & > p {
          font-size: .8rem;
          color: #fc4f2c;
          font-weight: 600;
        }
      }
    }
  }

  .tpzs {
    font-size: 1.06667rem;
    text-align: left;
    padding-left: 3%;
    margin-top: 1rem;
  }

  .swiper-container {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin: 1rem auto 0;
  }

  .swiper-slide img {
    width: 100%;
    display: block;
  }

  .sm {
    display: flex;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: .33333rem 3%;
    position: relative;

    .lef {
      max-width: 100%;

      h1 {
        color: #000;
        font-size: 1.06667rem;
      }

      & > * {
        margin: .33333rem 0;
      }

      p {
        font-size: .93333rem;
        color: #272b45;
      }

      .tips {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;


        span {
          color: #fff;
          display: inline-block;
          border-radius: .33333rem;
          margin-right: .33333rem;
          font-size: 12PX;
          padding: .33333rem 1rem;
          margin-bottom: .33333rem;
        }

        span:nth-child(1n) {
          background: #f6287e;
        }

        span:nth-child(2n) {
          background: #fa9a0a;
        }

        span:nth-child(3n) {
          background: #0aa5ff;
        }

      }


      .sf {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          width: 1.33333rem;
          height: 1.33333rem;
          background: url(/img/beauty/money.png) no-repeat 50% / 100%;
        }
      }
    }

    .right {
      position: absolute;
      display: none;
      right: 1.66667rem;
      text-align: center;
      color: #333;

      img {
        width: 3.66667rem;
        display: block;
      }
    }
  }

  .foot {
    width: 100%;
    padding: .66667rem 3%;

    h1 {
      text-align: left;
      color: rgba(0, 0, 0, .87);
      font-size: 1.06667rem;
      padding: .66667rem 0;
    }

    .dplayer {
      position: relative;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      line-height: 1;
    }
  }

  .jianjie {
    text-align: left;
    padding: 1rem 3%;

    h2 {
      color: #000;
      font-size: .93333rem;
    }

    p {
      padding: .66667rem 0;
      color: #28211f;
    }
  }

  .foots {
    width: 100%;
    max-width: 500px;
    position: fixed;
    bottom: 0;
    height: 3.66667rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 .33333rem rgba(0, 0, 0, .35);

    a {
      background: linear-gradient(90deg, #fc78af, #f22678);
      color: #fff;
      width: 70%;
      height: 60%;
      border-radius: .13333rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      text-decoration: none;
    }
  }
}
</style>