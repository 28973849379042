<template>
  <div class="withdraw">
    <Head :title="$t('setting.redeem_points_title')" background="transparent" color="#fff" zIndex="2000">
      <span @click="goRecords">{{ $t('setting.withdrawal_records') }}</span>
    </Head>
    <div v-show="isBank" class="top">
      <p>{{bankInfo.bankinfo + ' ('+ bankInfo.bankid +')'}}</p>
      <i class="van-badge__wrapper van-icon van-icon-success"></i>
    </div>
    <div class="top">
      <van-dropdown-menu>
        <van-dropdown-item v-model="typeValue" :options="optionsList"/>
      </van-dropdown-menu>
      <div class="van-dropdown-item van-dropdown-item--down" style="top: 0px; display: none;">
      </div>
      <p class="types">{{ $t('setting.withdrawal_method') }}</p></div>
    <div class="top center">
      <p>{{ $t('setting.redeem_points') }}</p>
      <div class="ipt">
        <p>{{ lang === 'en_us' ? '₩':''}}</p>
        <input v-model="amount" :placeholder="$t('setting.maximum') +userInfo.money" type="number">
        <span @click="amount=userInfo.money">{{ $t('setting.all') }}</span>
      </div>
      <div class="sm">
        <div class="syjf">{{ $t('setting.remaining_points') }}：<span>{{ userInfo.money }}{{ lang === 'en_us' ? '₩':''}}</span>
        </div>
      </div>
    </div>
    <div class="btn" @click="withdraw">
      <a>{{ $t('setting.redeem_points_now') }}</a>
    </div>
    <van-popup v-model="showPopover" class="van-dialog">
      <div class="van-dialog__header">{{ $t('setting.enter_payment_password_place') }}</div>
      <div class="van-dialog__content">
        <div class="van-cell van-field">
          <div class="van-cell__value van-cell__value--alone van-field__value">
            <div class="van-field__body">
              <input id="van-field-9-input" v-model="paypassword" class="van-field__control"
                     :placeholder="$t('setting.swap_pwd_tips3')"
                     type="password">
            </div>
          </div>
        </div>
      </div>
      <div class="van-hairline--top van-dialog__footer">
        <button class="van-button van-button--default van-button--large van-dialog__cancel" type="button"
                @click="showPopover=false">
          <div class="van-button__content"><span class="van-button__text">{{ $t('setting.cancel') }}</span></div>
        </button>
        <button class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left"
                type="button"
                @click="submit">
          <div class="van-button__content"><span class="van-button__text">{{ $t('setting.confirm') }}</span></div>
        </button>
      </div>
    </van-popup>
    <van-popup v-model="showTips" class="van-dialog">
      <div class="van-dialog__header">{{ $t('setting.prompt') }}</div>
      <div class="van-dialog__content">
        <div class="van-dialog__message van-dialog__message--has-title">{{ $t('setting.not_bank_card_tips') }}</div>
      </div>
      <div class="van-hairline--top van-dialog__footer">
        <button class="van-button van-button--default van-button--large van-dialog__cancel" type="button"
                @click="showTips=false">
          <div class="van-button__content">
            <span class="van-button__text">{{ $t('setting.cancel') }}</span>
          </div>
        </button>
        <button class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left"
                type="button"
                @click="confirm">
          <div class="van-button__content"><span class="van-button__text">{{ $t('setting.confirm') }}</span></div>
        </button>
      </div>
    </van-popup>


  </div>
</template>

<script>
import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      showPopover: false,
      showTips:false,
      typeValue: 1,
      isBank: false,
      bankInfo: {},
      balance: 0,
      amount: '',
      optionsList: [
        {
          text: this.$t('setting.Bank_card'),
          value: 1
        }
      ],
      minAmount: 10,
      paypassword: ''
    };
  },
  computed: {
    lang() {
      return localStorage.getItem('lang') ? localStorage.getItem('lang') : process.env.VUE_APP_DEFAULT_LANG

    },
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  methods: {
    confirm() {
      this.$router.push('/bind')
    },
    withdraw() {
      if(!this.isBank) {
        this.showTips = true
        return
      }
      if (this.amount === '' || this.amount === null || this.amount === undefined || this.amount === 0) {
        this.$toast(this.$t('setting.enter_the_exchange_amount_place'))
        return
      }
      if (this.amount < this.minAmount) {
        this.$toast(this.$t('setting.the_amount_of_cash_withdrawal_cannot_be_less_than') + this.minAmount)
        return;
      }
      this.showPopover = true
    },
    submit() {
      this.$http({
        method: 'post',
        url: 'user_set_withdraw',
        data: {
          money: this.amount,
          bank_id: this.bankInfo.id,
          paypassword: this.paypassword
        }
      }).then(res => {
        if (res.code === 200) {
          this.$toast(res.msg)
          this.showPopover = false
          this.amount = ''
        } else {
          this.$toast(res.msg)
          this.showPopover = false
          this.amount = ''
        }
      })
    },
    goRecords() {
      this.$router.push(('/rechargedetail'))
    }
  },
  created() {
    this.$http({
      method: 'get',
      url: 'user_info',
      isLoading: true
    }).then(res => {
      if (res.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      } else {
        this.$toast(res.msg)
      }

    })
    this.$http({
      method: 'get',
      url: 'user_get_bank',
      isLoading: true
    }).then(res => {
      this.isBank = res.data?.is_bank
      if (res.data.info) {
        this.bankInfo = res.data.info
      }
    })
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
  height: auto;
}

/deep/ .van-dropdown-item {
  width: 90%;
  left: 0;
  right: 0;
  margin: auto;

  .van-cell__title {
    color: #a9a7b4;
  }
}

/deep/ .van-nav-bar__content {
  .van-icon {
    color: #fff !important;
  }

  .van-nav-bar__title {
    color: #fff !important;
  }

  .van-nav-bar__right {
    color: #fff !important;
  }

}

.header {
  box-shadow: none;
}

.van-hairline--bottom::after {
  border-bottom-width: 0;
}

.withdraw {
  width: 100%;
  height: 100%;
  background: url(/img/mine/withdraw_bg.png) no-repeat top / 100% auto;

  .van-dialog {
    border-radius: 4px;
    background: url(/img/mine/withdraw_dialog_bg.png) no-repeat 50% / 100% auto;

    .van-dialog__header {
      background: linear-gradient(133deg, #e64588, #f22678);
      padding: 10px;
      color: #fff;
      font-size: 14px;
    }

    .van-dialog__content {
      .van-field {
        width: 50%;
        margin: 0 auto;
        background: #f7f7f7;
        padding: 4px 10px;
        border-radius: 4px;

        .van-field__value {
          border: 1px solid #c5c5c5;

          input {
            text-align: center;
          }
        }
      }
    }

    .van-button--default {
      background: none;
      line-height: 48px;
    }

  }

  .top {
    width: 90%;
    background: #fff;
    margin: .6rem auto;
    padding: 1rem 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: .33333rem;
    position: relative;

    p {
      margin-right: .66667rem;
      color: #442b89;
    }

    .van-icon {
      color: #cc2996 !important;
    }

    .types {
      position: absolute;
      right: 0;
    }
  }

  .top.center {
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-right: .66667rem;
      color: #442b89;
      padding-right: .66667rem;
    }

    .ipt {
      display: flex;
      width: 100%;
      padding: 1rem 0 0;
      justify-content: space-between;
      align-items: center;

      input {
        border: none;
        flex: 1;
        padding: .66667rem 0;
      }

      span {
        padding-left: 1rem;
        color: #735fb1;
      }
    }

    .sm {
      width: 100%;
      padding: .66667rem 0;
      border-top: 1px solid #f5f6f7;

      & > div {
        width: 100%;
        text-align: left;
        padding-top: .66667rem;
        color: #3a1a84;

        span {
          color: #d53dde;
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .66667rem;
    margin-top: 3.33333rem;

    a {
      background: linear-gradient(180deg, #fc78af, #f22678);
      width: 100%;
      color: #fff;
      padding: .86667rem 0;
      border-radius: .13333rem;
      font-size: .86667rem;
    }
  }
}
</style>
