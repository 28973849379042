<template>
  <div class="message">
    <Head :title="$t('setting.message')"></Head>
    <div class="tabs__wrap">
      <van-tabs v-model="active" @change="changeTabs">
        <van-tab :title="$t('setting.consultation_announcement')" name="0"></van-tab>
        <van-tab :title="$t('setting.member_information')" name="1">
          <ul class="list">
            <li @click="toDetail(item)" v-for="(item,index) in list" :key="index">
              <i class="van-badge__wrapper van-icon" style="font-size: 40px;">
                <img class="van-icon__image" src="/img/notice.png">
              </i>
              <div>
                <div class="dot" v-show="item.is_read === 0"></div>
                <h1>{{ item.title }} <span>{{ item.created_at }}</span>
                </h1>
                <p>{{item.content}}</p>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      active: null,
      list: []
    }
  },
  methods: {
    changeTabs(e) {
      this.active = e
    },
    toDetail(item) {
      const str = this.$objToString(item)
      this.$router.push(`/notions?${str}`)
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.active = this.$route.query.type
    }
    this.$http({
      method: 'get',
      url: 'get_message_list',
    }).then(res => {
      this.list = res.data
    })
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__nav {
  height: 100%;
  box-sizing: content-box !important;

  .van-tab {
    color: rgb(40, 33, 31)
  }

  .van-tab--active {
    color: #f42f7e;
  }

  .van-tabs__line {
    height: 3px;
    width: 60px;
    background-color: rgb(244, 47, 126);
  }
}

.message {
  width: 100%;
  height: 100%;
  background: #fff;
  .list {
    border-top: .66667rem solid #faf9fe;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      padding: .66667rem 0 .66667rem 1rem;
      position: relative;
      &>div {
        width: calc(92% - 40PX);
        overflow: hidden;
        height: 100%;
        padding-left: 1rem;
        box-sizing: border-box;
        position: relative;
        .dot {
          position: absolute;
          right: 0;
          top: 70%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #ee0a24;
          padding: 0;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: #ededed;
          bottom: 0;
          right: 0;
        }
        h1 {
          color: #2e2e2e;
          text-align: left;
          font-size: .93333rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 400;
            color: #b3b3b3;
            font-size: 12PX;
          }
        }
        p {
          color: #b3b3b3;
          font-size: .8rem;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &>* {
          padding: .33333rem 0;
        }
      }
    }
  }
}

</style>