<template>
  <div class="jfdetail">
    <Head :title="$t('setting.points_details')"></Head>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div aria-busy="false" class="van-list" role="feed">
        <div class="van-cell-group van-hairline--top-bottom">
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="$t('setting.no_more')"
              @load="getData"
          >
            <div v-for="(item,index) in list" :key="index" class="van-cell">
              <div class="van-cell__title">
                <div class="box">
                  <div class="left">{{ item.type_text.slice(0, 1) }}</div>
                  <div><p style="color: rgb(41, 41, 41);">{{ item.type_text }}</p><span class="sp1"
                  >{{ item.created_at }}</span><span
                      class="sp2"
                      style="display: block; color: rgb(135, 135, 135); font-size: 12px;">{{ item.desc }}</span>
                  </div>
                </div>
              </div>
              <div class="van-cell__value"><span
                  :style="(item.money*1)>0 ? {color: 'rgb(60, 209, 151)'}:{color: 'rgb(242, 96, 96)'}">{{
                  (item.money * 1) > 0 ? `+${item.money}` : item.money
                }}</span>
              </div>
            </div>
          </van-list>
        </div>
        <div class="van-list__placeholder"></div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      page: 1
    }
  },
  methods: {
    getData() {
      this.$http({
        method: 'get',
        url: 'get_jf_list',
        data: {
          page: this.page
        },
        isLoading: this.page === 1,
      }).then(res => {
        if (this.page === 1) {
          this.list = res.data.data
        } else {
          this.list = [...this.list, ...res.data.data]
        }
        this.loading = false;
        this.page++
        if (this.list.length >= res.data.total) {
          this.finished = true;
        }
      })
    },
    onRefresh() {
      this.page = 1
      this.isLoading = true
      this.$http({
        method: 'get',
        url: 'get_jf_list',
        isLoading: true,
      }).then(res => {
        this.list = res.data.data
        this.isLoading = false
      })
    }
  },
  created() {
    this.list = []
  }
}
</script>

<style lang="less" scoped>
.jfdetail {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-cell-group {
    width: 100%;
    margin: 10px auto;
    background: none;
    position: relative;

    .van-cell {
      margin-bottom: 8px;

      .van-cell__title {
        width: 80%;
        flex: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #a9a7b4;
        font-size: 14px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .left {
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #fa67a3, #f32a7a);
            margin-right: 10px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
          }

          p {
            font-weight: 600;
          }

          .sp1 {
            font-size: 12px;
          }

          .sp2 {
            color: #9c91c0 !important;
          }
        }
      }

      .van-cell__value {
        color: #f26060;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600;
        flex: auto;
        position: relative;
        overflow: hidden;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
  }
}

</style>