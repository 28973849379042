import Vue from 'vue';
import App from './App.vue';
import Vant, {DropdownItem, DropdownMenu, Icon, PullRefresh} from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import VueI18n from 'vue-i18n';
import 'video.js/dist/video-js.css'

const objToString = (item) => {
    let str = ''
    for (let i in item) {
        if (str === '') {
            str += `${i}=${item[i]}`
        } else {
            str += `&${i}=${item[i]}`
        }
    }
    return str
}
Vue.prototype.$http = http
Vue.prototype.common = common
Vue.prototype.$objToString = objToString
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(VueI18n);
Vue.use(PullRefresh);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
const i18n = new VueI18n({
    globalInjection: true,
    locale: localStorage.getItem("lang") || process.env.VUE_APP_DEFAULT_LANG, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
    messages: {
        'zh_cn': require('./assets/languages/zh_cn.json'),
        'en_us': require('./assets/languages/en_us.json'),
        'ko_kr': require('./assets/languages/ko_kr.json'),
        'ja_jp': require('./assets/languages/ja_jp.json'),
        'vi_vn': require('./assets/languages/vi_vn.json'),
    }
});
new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
