import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue' /* 首页 */
import Mine from '../pages/mine/index.vue' /* 我的 */
import Choose from '../pages/choose/index.vue' /* 选妃 */
import ChooseDetail from '../pages/choose/beautys.vue' /* 选妃详情 */
import Movie from '../pages/movie/index.vue' /* 视频 */
import Game from '../pages/game/index.vue' /* 游戏 */
import Lottery from '../pages/game/lottery.vue' /* 游戏详情 */
import Login from '../pages/login/index.vue' /* 登录 */
import Register from '../pages/login/register.vue' /* 注册 */
import PointsDetails from '../pages/mine/pointsDetails.vue' /* 积分明细 */
import RechargeDetail from '../pages/mine/rechargeDetail.vue' /* 积分明细 */
import DataRecord from '../pages/mine/dataRecord.vue' /* 资料记录 */
import Infomation from '../pages/mine/info.vue' /* 基本信息 */
import changename from '../pages/mine/changename.vue' /* 修改姓名 */
import changenumber from '../pages/mine/changenumber.vue' /* 修改手机 */
import Notice from '../pages/mine/Notice.vue' /* 公告列表 */
import NoticeDetail from '../pages/mine/noticeDetail.vue' /* 公告详情 */
import MyMove from '../pages/mine/mymove.vue' /* 我的电影 */
import Changepas from '../pages/mine/changepas.vue' /* 修改登入密码 */
import Fundpas from '../pages/mine/fundpas.vue' /* 修改兑换密码 */
import PlayVideo from '../pages/movie/PlayVideo' /* 视频播放页面 */
import BindCard from '../pages/mine/BindCard' /* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw' /* 积分兑换 */


Vue.use(VueRouter)
const routes = [
    {path: '/', redirect: '/Home', component: Home},
    {path: '/Home', name: 'home', component: Home},
    {path: '/Choose', name: 'choose', component: Choose,meta:{keepAlive:true}},
    {path: '/beautys', name: 'beautys', component: ChooseDetail},
    {path: '/Mine', name: 'mine', component: Mine},
    {path: '/movie', name: 'movie', component: Movie,meta:{keepAlive:true}},
    {path: '/Game', name: 'game', component: Game},
    {path: '/lottery', name: 'lottery', component: Lottery},
    {path: '/login', name: 'login', component: Login},
    {path: '/res', name: 'register', component: Register},
    {path: '/jfdetail', name: 'jfdetail', component: PointsDetails},
    {path: '/rechargedetail', name: 'rechargedetail', component: RechargeDetail},
    {path: '/rwdetail', name: 'rwdetail', component: DataRecord},
    {path: '/info', name: 'info', component: Infomation},
    {path: '/changename', name: 'changename', component: changename},
    {path: '/changenumber', name: 'changenumber', component: changenumber},
    {path: '/notion', name: 'notion', component: Notice},
    {path: '/notions', name: 'notions', component: NoticeDetail},
    {path: '/mymove', name: 'mymove', component: MyMove},
    {path: '/changepas', name: 'changepas', component: Changepas},
    {path: '/fundpas', name: 'fundpas', component: Fundpas},
    {path: '/moviedetail', name: 'moviedetail', component: PlayVideo},
    {path: '/bind', name: 'bind', component: BindCard},
    {path: '/Withdraw', name: 'Withdraw', component: Withdraw},
    {path: '*', redirect: '/'}
];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token')
    if (to.path === '/Home' || to.path === '/res' || to.path === '/login' || to.path === '/Movie' || to.path === '/Choose') {
       if(to.path !== '/Movie') {
           localStorage.removeItem('movieScrollTop')
       }
       if(to.path !== '/Choose') {
           localStorage.removeItem('scrollTop')
       }
       next();
    } else {
        if (!token) {
            next({
                path: '/login',
            });
        } else {
            next();
        }
    }
})

export default router