<template>
  <div class="game">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__title van-ellipsis" style="padding-top: 2px">{{ $t('foorter.welfare') }}</div>
      </div>
    </div>
    <div class="data">
      <div class="item" @click="toMovie">
        <div class="left">
          <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
            <img class="van-icon__image" src="/img/game/video.png">
          </i>
          <div>{{ $t('game.member_videos') }}</div>
        </div>
        <div class="right">
          <span>{{ $t('game.ride_on_him_and_fuck_him') }}</span>
          <div class="van-badge__wrapper" style="height: 32px">
            <i class="van-badge__wrapper van-icon" style="font-size: 30px;">
              <img class="van-icon__image" src="/img/game/user_avatar.png">
            </i>
            <div class="van-badge van-badge--top-right van-badge--dot van-badge--fixed"></div>
          </div>
          <i class="van-badge__wrapper van-icon van-icon-arrow"></i></div>
      </div>
      <div class="item" @click="toChoose">
        <div class="left"><i class="van-badge__wrapper van-icon" style="font-size: 30px;">
          <img class="van-icon__image" src="/img/game/welfare.png"></i>
          <div>{{ $t('game.welfare') }}</div>
        </div>
        <div class="right"><span>{{ $t('game.new_ID') }}:{{ Math.ceil(Math.random() * (900 + 1) + 100) }}***</span>
          <div class="van-badge__wrapper" style="height: 32px"><i class="van-badge__wrapper van-icon"
                                                                  style="font-size: 30px;"><img
              class="van-icon__image" src="/img/game/user_avatar.png"></i>
            <div class="van-badge van-badge--top-right van-badge--dot van-badge--fixed"></div>
          </div>
          <i class="van-badge__wrapper van-icon van-icon-arrow"></i></div>
      </div>
    </div>
    <div class="gameCard">
      <div v-for="(item,index) in dataList" :key="index" class="item" @click="goGame(item)">
        <div class="dfs">
          <i class="van-badge__wrapper van-icon">
            <img :src="'/img/game/wangguan.png'" class="van-icon__image"/>
          </i> {{ $t('game.membership_rights') }}
        </div>
        <p>{{ item.name }}</p>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: []
    };
  },
  methods: {
    goGame(item) {
      const str = this.$objToString(item)
      this.$router.push(`/lottery?${str}`)
    },
    toMovie() {
      this.$router.push('movie')
    },
    toChoose() {
      this.$router.push('choose')
    }
  },
  mounted() {
    this.$http({
      method: 'get',
      url: 'lottery_class',
      isLoading: true
    }).then(res => {
      this.dataList = res.data
    })
  }
}
</script>

<style lang='less' scoped>
.van-icon__image {
  display: block;
  width: 1em;
  height: 1em;
  -o-object-fit: contain;
  object-fit: contain;
}


.game {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-nav-bar {
    z-index: 999;
    background: none;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .van-nav-bar__content {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--van-nav-bar-height);

      .van-nav-bar__title {
        color: #000;
        font-weight: 600;
        max-width: 60%;
        margin: 0 auto;
        font-size: var(--van-nav-bar-title-font-size);
        line-height: var(--van-line-height-lg);
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 var(--van-padding-md);
        font-size: var(--van-font-size-md);
      }

      .van-nav-bar__right {
        right: 0;
      }
    }

    .van-hairline--bottom {
      border-width: 0;
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 0 solid var(--van-border-color);
      transform: scale(.5);
    }
  }

  .van-tab--grow {
    padding: 0 5px;
  }


  .data {
    .item {
      width: 92%;
      height: 4rem;
      background: #fff;
      margin: 0 auto 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: .33333rem;
      padding: 0 .66667rem;
      box-shadow: 0 .13333rem .66667rem rgba(250, 26, 67, .17);

      & > div {
        display: flex;
        align-items: center;
        height: 30px;

      }

      .left div {
        color: #000;
        margin-left: .33333rem;
        font-size: .93333rem;
      }

      .right {
        .van-badge__wrapper {
        }

        span {
          color: #666;
          font-size: .8rem;
          margin-right: .33333rem;
        }

        i {
          margin-left: .33333rem;
          color: #999;
        }

        .van-badge--dot {
          width: var(--van-badge-dot-size);
          min-width: 0;
          height: var(--van-badge-dot-size);
          background: var(--van-badge-dot-color);
          border-radius: 100%;
          border: none;
          padding: 0;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }

        .van-icon {
          font-size: 16px;

        }

        .van-icon-arrow:before {
          display: inline-block;
        }
      }
    }

  }

  .gameCard {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 4rem;

    & > .item {
      width: 100%;
      height: 9rem;
      margin: 0 0 .66667rem;
      border-radius: .66667rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      & > p {
        margin-top: 1.66667rem;
        margin-left: 1.66667rem;
        font-size: .93333rem;
        font-weight: 600;
        color: #3d4651;
        text-align: left;
      }

      .dfs {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: .73333rem;
        padding-left: 0;
        margin-top: .53333rem;


        i {
          margin-right: 0;
        }

        .van-icon {
          font-size: 16px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

    & > .item:nth-child(n) {
      background: url(/img/game/bg1.png) no-repeat 50% / 100%;
    }

    & > .item:nth-child(2n) {
      background: url(/img/game/bg2.png) no-repeat 50% / 100%;
    }
  }

}
</style>