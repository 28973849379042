<template>
  <div class="notes">
    <Head :title="$t('setting.member_information')"></Head>
    <div class="content">
      <div class="head">
        <i class="van-badge__wrapper van-icon" style="font-size: 40px;">
          <img class="van-icon__image" src="/img/notice.png">
        </i>
        <div>
          <h1>{{ info.title }}</h1>
          <p>{{ info.created_at }}</p>
        </div>
      </div>
      <div class="cs">{{ info.content }}</div>
    </div>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      active: null,
      info: {}
    }
  },
  methods: {
  },
  mounted() {
    if (this.$route.query.type) {
      this.active= this.$route.query.type
    }
    this.info =  this.$route.query
    this.$http({
      method: 'post',
      url: 'set_message',
      data: {
        id: this.info.id
      }
    })
  }
}
</script>

<style lang="less" scoped>
.notes {
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;
  .content {
    h1 {
      color: #2e2e2e;
      text-align: left;
      font-size: .93333rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-weight: 400;
      color: #828282;
      font-size: 13PX;
      text-align: left;
    }
    .cs {
      background: #fff;
      padding: .66667rem;
      color: #020208;
      text-align: justify;
    }
    .head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      margin-top: .66667rem;
      padding: .66667rem;
      border-bottom: 1px solid #a1a1a1;

      &>div {
        flex: 1;
        padding-left: 1rem;
        &>* {
          padding: .33333rem 0;
        }
      }
    }
  }
}

</style>