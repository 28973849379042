<template>
  <div class="name">
    <Head :title="$t('setting.change_real_name')" background="none">
      <span @click="save">{{ $t('setting.save') }}</span>
    </Head>
    <div class="van-cell-group van-hairline--top-bottom">
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label">
          <label id="van-field-1-label" for="van-field-1-input">{{ $t('setting.old_name') }}</label>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <input id="van-field-1-input" :value="oldValue" aria-labelledby="van-field-1-label"
                   class="van-field__control" readonly=""
                   type="text"></div>
        </div>
      </div>
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label">
          <label id="van-field-2-label" for="van-field-2-input">{{ $t('setting.new_name') }}</label>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <input id="van-field-2-input" v-model="value" :placeholder="$t('setting.new_name_placeholder')"
                   aria-labelledby="van-field-2-label" class="van-field__control" type="text">
          </div>
        </div>
      </div>
      <p>{{ $t('setting.change_real_name_tip') }}</p>
    </div>
  </div>
</template>

<script>
import Head from './head.vue'

export default {
  components: {
    Head
  },
  data() {
    return {
      value: ''
    };
  },
  computed: {
    oldValue() {
      return JSON.parse(localStorage.getItem('userInfo'))['name']
    }
  },
  methods: {
    save() {
      if (this.value === '' || this.value === null || this.value === undefined) {
        this.$toast(this.$t('setting.enter_the_modified_content_place'))
        return
      }
      this.$http({
        method: 'post',
        url: 'user_set_name',
        data: {
          name: this.value
        },
        isLoading: true
      }).then(res => {
        if (res.code === 200) {
          this.$router.back()
          this.$toast(res.msg)
        } else {
          this.$toast.fail(res.msg)

        }
      })
    }
  }
};
</script>

<style lang='less' scoped>
.van-cell--clickable {
  cursor: pointer;
}

.name {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  p {
    color: #f53d87;
    font-size: .8rem;
    padding: 1rem 0;
  }

  .van-cell-group {
    background: none;
    position: relative;

    .van-cell {
      padding: 16px 20px;

      .van-cell__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;
      }
    }

    .van-field {
      width: 100%;
      border-radius: 0;
      margin: 5px auto;
      background: #fff;
    }
  }
}
</style>
