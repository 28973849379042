<template>
  <van-tabbar
      v-if="show"
      v-model="active"
      :border="true"
      :safe-area-inset-bottom="true"
      active-color="rgb(160, 96, 152)"
      inactive-color="rgb(200, 174, 217)"
      style="z-index:999"
  >
    <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span style="position: relative;z-index: 9">{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
            v-show="show !== 0"
            :alt="$t('foorter.index')"
            :src="props.active ? '/img/footer/home_active.png' : '/img/footer/home.png'"
        />
        <img
            v-show="show === 0"
            :alt="$t('foorter.index')"
            :class="$t('foorter.index')"
            :src="props.active ? '/img/footer/home_active.png' : '/img/footer/home.png'"
        />
      </template>
    </van-tabbar-item>
    <!-- 戲院 -->
    <van-tabbar-item to="/Movie">
      <span style="position: relative;z-index: 9">{{ $t('foorter.theater') }}</span>
      <template #icon="props">
        <img
            v-show="show !== 1"
            :alt="$t('foorter.theater')"
            :src="props.active ? '/img/footer/theater_active.png' : '/img/footer/theater.png'"
        />
        <img
            v-show="show === 1"
            :alt="$t('foorter.theater')"
            :class="$t('foorter.theater')"
            :src="props.active ? '/img/footer/theater_active.png' : '/img/footer/theater.png'"
        />
      </template>
    </van-tabbar-item>
    <!-- 資源 -->
    <van-tabbar-item to="/Choose">
      <span style="position: relative;z-index: 9">{{ $t('foorter.resource') }}</span>
      <template #icon>
        <div class="jump">
          <div>
            <img
                class="resource"
                src="/img/footer/resource.png"
            />
          </div>
        </div>

      </template>
    </van-tabbar-item>
    <!-- 福利 -->
    <van-tabbar-item to="/Game">
      <span style="position: relative;z-index: 9">{{ $t("foorter.welfare") }}</span>
      <template #icon="props">
        <img
            v-show="show !== 3"
            :alt="$t('foorter.welfare')"
            :src="props.active ? '/img/footer/welfare_active.png' : '/img/footer/welfare.png'"
        />
        <img
            v-show="show === 3"
            :alt="$t('foorter.welfare')"
            :class="$t('foorter.welfare')"
            :src="props.active ? '/img/footer/welfare_active.png' : '/img/footer/welfare.png'"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span style="position: relative;z-index: 9">{{ $t("foorter.mine") }}</span>
      <template #icon="props">
        <img
            v-show="show !== 4"
            :alt="$t('foorter.mine')"
            :src="props.active ? '/img/footer/mine_active.png' : '/img/footer/mine.png'"
        />
        <img
            v-show="show === 4"
            :alt="$t('foorter.mine')"
            :class="$t('foorter.mine')"
            :src="props.active ? '/img/footer/mine_active.png' : '/img/footer/mine.png'"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import index from "vuex";

export default {
  computed: {
    index() {
      return index
    }
  },
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "movie") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "movie") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
.van-tabbar {
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .17);
  border-radius: 20px 20px 0 0;
}

.van-tabbar-item__icon img {
  height: 22px;
}

.van-tabbar-item {
  font-size: 12px;
}

.van-tabbar-item__text {
  position: relative;
  z-index: 9;
}

.jump {
  position: relative;
  height: 22PX;
  width: 22PX;

  & > div {
    width: 3.66667rem;
    height: 3.66667rem;
    position: absolute;
    left: -1.2rem;
    right: 0;
    top: -1.66667rem;
    margin: auto;
    border: .6rem solid #fff;
    background: #fff;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

}


[class*="van-hairline"]::after {
  border: none !important;
}
</style>
