<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'

export default {

  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status: 0
    };
  },
  mounted() {
    this.updateFontSize();
    window.addEventListener('resize', this.updateFontSize);
    this.$http({
      method: 'get',
      url: 'base_info',
    }).then(res=> {
      localStorage.setItem('kefu',res.data.kefu)
    })
  },
  methods: {
    updateFontSize() {
      const currentWidth = document.documentElement.clientWidth;
      const newFontSize = (currentWidth * 4.16667) / 100
      document.documentElement.style.fontSize = `${newFontSize > 18 ? 18 : newFontSize}px`;
    }

  }
}
</script>

<style lang="scss">
body .van-toast {
  background: linear-gradient(180deg, rgba(252, 120, 175, .44), rgba(242, 38, 120, .5));
  box-sizing: content-box !important;

  .van-icon {
    font-size: 16px !important;
  }
}

.van-toast.van-toast--loading {
  box-sizing: content-box !important;

}

body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei, sans-serif !important;
}

body .van-toast .van-toast__icon {
  font-size: 50px;
}

*, :after, :before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-style: normal;
  box-sizing: border-box !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  width: 100%;
  max-width: 500PX;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: .86667rem;
  color: #000;
  top: 0;
  margin: auto;
  box-shadow: 0 0 .13333rem rgba(0, 0, 0, .2);
}

:root {
  --van-black: #000;
  --van-white: #fff;
  --van-gray-1: #f7f8fa;
  --van-gray-2: #f2f3f5;
  --van-gray-3: #ebedf0;
  --van-gray-4: #dcdee0;
  --van-gray-5: #c8c9cc;
  --van-gray-6: #969799;
  --van-gray-7: #646566;
  --van-gray-8: #323233;
  --van-red: #ee0a24;
  --van-blue: #1989fa;
  --van-orange: #ff976a;
  --van-orange-dark: #ed6a0c;
  --van-orange-light: #fffbe8;
  --van-green: #07c160;
  --van-gradient-red: linear-gradient(90deg, #ff6034, #ee0a24);
  --van-gradient-orange: linear-gradient(90deg, #ffd01e, #ff8917);
  --van-primary-color: var(--van-blue);
  --van-success-color: var(--van-green);
  --van-danger-color: var(--van-red);
  --van-warning-color: var(--van-orange);
  --van-text-color: var(--van-gray-8);
  --van-text-color-2: var(--van-gray-6);
  --van-text-color-3: var(--van-gray-5);
  --van-text-link-color: #576b95;
  --van-active-color: var(--van-gray-2);
  --van-active-opacity: .6;
  --van-disabled-opacity: .5;
  --van-background-color: var(--van-gray-1);
  --van-background-color-light: var(--van-white);
  --van-padding-base: 4px;
  --van-padding-xs: 8px;
  --van-padding-sm: 12px;
  --van-padding-md: 16px;
  --van-padding-lg: 24px;
  --van-padding-xl: 32px;
  --van-font-size-xs: 10px;
  --van-font-size-sm: 12px;
  --van-font-size-md: 14px;
  --van-font-size-lg: 16px;
  --van-font-weight-bold: 500;
  --van-line-height-xs: 14px;
  --van-line-height-sm: 18px;
  --van-line-height-md: 20px;
  --van-line-height-lg: 22px;
  --van-base-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui", "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  --van-price-integer-font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif;
  --van-animation-duration-base: .3s;
  --van-animation-duration-fast: .2s;
  --van-animation-timing-function-enter: ease-out;
  --van-animation-timing-function-leave: ease-in;
  --van-border-color: var(--van-gray-3);
  --van-border-width-base: 1px;
  --van-border-radius-sm: 2px;
  --van-border-radius-md: 4px;
  --van-border-radius-lg: 8px;
  --van-border-radius-max: 999px;
  --van-nav-bar-height: 46px;
  --van-nav-bar-background-color: var(--van-background-color-light);
  --van-nav-bar-arrow-size: 16px;
  --van-nav-bar-icon-color: var(--van-primary-color);
  --van-nav-bar-text-color: var(--van-primary-color);
  --van-nav-bar-title-font-size: var(--van-font-size-lg);
  --van-nav-bar-title-text-color: var(--van-text-color);
  --van-nav-bar-z-index: 1;
  --van-notice-bar-height: 40px;
  --van-notice-bar-padding: 0 var(--van-padding-md);
  --van-notice-bar-wrapable-padding: var(--van-padding-xs) var(--van-padding-md);
  --van-notice-bar-text-color: var(--van-orange-dark);
  --van-notice-bar-font-size: var(--van-font-size-md);
  --van-notice-bar-line-height: 24px;
  --van-notice-bar-background-color: var(--van-orange-light);
  --van-notice-bar-icon-size: 16px;
  --van-notice-bar-icon-min-width: 24px;
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  --van-tabbar-item-font-size: var(--van-font-size-sm);
  --van-tabbar-item-text-color: var(--van-gray-7);
  --van-tabbar-item-active-color: var(--van-primary-color);
  --van-tabbar-item-active-background-color: var(--van-background-color-light);
  --van-tabbar-item-line-height: 1;
  --van-tabbar-item-icon-size: 22px;
  --van-tabbar-item-icon-margin-bottom: var(--van-padding-base);
  --van-tab-text-color: var(--van-gray-7);
  --van-tab-active-text-color: var(--van-text-color);
  --van-tab-disabled-text-color: var(--van-text-color-3);
  --van-tab-font-size: var(--van-font-size-md);
  --van-tab-line-height: var(--van-line-height-md);
  --van-tabs-default-color: var(--van-danger-color);
  --van-tabs-line-height: 44px;
  --van-tabs-card-height: 30px;
  --van-tabs-nav-background-color: var(--van-background-color-light);
  --van-tabs-bottom-bar-width: 40px;
  --van-tabs-bottom-bar-height: 3px;
  --van-tabs-bottom-bar-color: var(--van-danger-color);
  --van-badge-size: 16px;
  --van-badge-color: var(--van-white);
  --van-badge-padding: 0 3px;
  --van-badge-font-size: var(--van-font-size-sm);
  --van-badge-font-weight: var(--van-font-weight-bold);
  --van-badge-border-width: var(--van-border-width-base);
  --van-badge-background-color: var(--van-danger-color);
  --van-badge-dot-color: var(--van-danger-color);
  --van-badge-dot-size: 8px;
  --van-badge-font-family: -apple-system-font, Helvetica Neue, Arial, sans-serif;
  --van-cell-font-size: var(--van-font-size-md);
  --van-cell-line-height: 24px;
  --van-cell-vertical-padding: 10px;
  --van-cell-horizontal-padding: var(--van-padding-md);
  --van-cell-text-color: var(--van-text-color);
  --van-cell-background-color: var(--van-background-color-light);
  --van-cell-border-color: var(--van-border-color);
  --van-cell-active-color: var(--van-active-color);
  --van-cell-required-color: var(--van-danger-color);
  --van-cell-label-color: var(--van-text-color-2);
  --van-cell-label-font-size: var(--van-font-size-sm);
  --van-cell-label-line-height: var(--van-line-height-sm);
  --van-cell-label-margin-top: var(--van-padding-base);
  --van-cell-value-color: var(--van-text-color-2);
  --van-cell-icon-size: 16px;
  --van-cell-right-icon-color: var(--van-gray-6);
  --van-cell-large-vertical-padding: var(--van-padding-sm);
  --van-cell-large-title-font-size: var(--van-font-size-lg);
  --van-cell-large-label-font-size: var(--van-font-size-md);
}
</style>
