<template>
  <div class="mymovie">
    <Head :title="$t('setting.my_movies')"></Head>
    <div class="top">
      <div class="left">
        <div class="van-image van-image--round" style="width: 60px; height: 60px;">
          <img class="van-image__img" src="https://ma.sesxe18.com/pic/avatar1.png" style="object-fit: cover;"></div>
        <div class="center">
          <h1>Hi,{{ userInfo.username }}</h1>
          <p>{{ $t('video.complete_the_task') }}</p>
        </div>
      </div>
      <div class="right">{{ $t('video.movie_reel') }}:{{ userInfo.movie_tick ? userInfo.movie_tick : 0 }}</div>
    </div>
    <div class="item" data-v-1c7d11ce="">
      <div class="title" data-v-1c7d11ce=""><span data-v-1c7d11ce=""><i class="van-badge__wrapper van-icon"
                                                                        data-v-1c7d11ce="" style="font-size: 22px;"><img
          class="van-icon__image"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAnCAYAAADU3MIsAAAAAXNSR0IArs4c6QAAAENJREFUSEtjZCAAPutU/0dXwjiqCRICjL8yFmOEDtuMWHgAYQ29UU2joYeSg0aTETQ4RgNiNCBQ65bRqgaWIuhW5wIABmbNd4/ZYS4AAAAASUVORK5CYII=">
        </i> {{ $t('video.exchange_record') }}</span></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="list.length ? $t('setting.no_more') : ''"
            @load="getList"
        >
          <div v-show="list.length === 0" class="van-empty" style="margin-top: 100px;">
            <div class="van-empty__image" style="width: 80px; height: 80px;">
              <img src="/img/mine/empty.png">
            </div>
            <p class="van-empty__description">{{ $t('video.no_data_yet') }}</p></div>
          <ul class="hot">
            <li v-for="(item,index) in list" :key="index">
              <div class="van-image">
                <img :src="item.video_detail.vod_pic"
                     class="van-image__img"
                     style="object-fit: cover;"></div>
              <div class="r">
                <span>{{ item.video_detail.vod_name }}</span>
                <span>{{ $t('setting.exchange_time') }}：{{ item.created_at }}</span>
              </div>
            </li>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: true,
      list: [],
      page: 1
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  methods: {
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.isLoading = true
      this.page = 1
      this.getList();
    },
    getList() {
      if (this.isLoading) {
        this.list = [];
        this.isLoading = false;
      }

      this.$http({
        method: 'get',
        url: 'get_duihuanlist',
        isLoading: true,
        data: {
          page: this.page
        }
      }).then((res) => {
        if (this.list.length) {
          this.list = this.list.concat(res.data.data)
        } else {
          this.list = res.data.data
        }
        this.page++
        this.loading = false;
        if (this.list.length >= res.data.total) {
          this.finished = true;
        }
      })
    },
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.van-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 32px 0;

}

.mymovie {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .hot {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;

    li {
      width: 100%;
      border-radius: .2rem;
      overflow: hidden;
      margin-top: .46667rem;
      height: 4.66667rem;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .van-image {
        width: 30%;
        height: 100%;
        border-radius: 4px;
        overflow: hidden;

        .van-image__img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .r {
        flex: 1;
        padding: 4px 0 4px 0.66665rem;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

        }

        & > :first-child {
          width: 100%;
          color: #000;
          font-weight: 600;
          text-align: left;
          font-size: 16px;
        }

        & > :last-child {
          width: 100%;
          font-size: 14px;
          text-align: left;
          color: #999;
        }
      }
    }
  }

  .top {
    width: 96%;
    display: flex;
    height: 8.33333rem;
    justify-content: space-between;
    align-items: center;
    background: url(/img/mine/movie.png) no-repeat 50% / 100%;
    border-radius: .13333rem;
    padding: 3% 3% 0;
    margin: 1rem auto 0;

    & > div {
      position: relative;
      top: -.66667rem;
    }

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      flex: 1;

      .van-image {
        border: 1px solid #fff;
        overflow: hidden;
        border-radius: 999px;
      }

      .center {
        padding-left: 5%;

        & > * {
          padding: .2rem 0;
        }

        h1 {
          font-size: .93333rem;
          color: #fff;
        }

        p {
          color: #fff;
          font-size: .76667rem;
        }
      }


    }

    .right {
      color: #f53d87;
      font-size: .76667rem;
      padding: .46667rem .33333rem;
      border-radius: 2rem;
      position: relative;
      top: -1.66667rem;
    }
  }

  .item[data-v-1c7d11ce] {
    width: 100%;
    margin: .33333rem auto .33333rem;
    padding: .33333rem 5%;
    height: calc(100% - 10.26667rem);

    .title[data-v-1c7d11ce] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .66667rem;

      span[data-v-1c7d11ce] {
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #4a338b;
      }
    }
  }
}

</style>