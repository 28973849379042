<template>
  <div class="move"  :style="{ 'display':isLoad?'block':'none'}">
    <van-nav-bar
        :title="info.vod_name"
        left-arrow
        @click-left="back"
    />
    <div :style="{position:'relative'}" style="display: block">
      <div ref="dplayer">
      </div>
<!--      <div v-if="hlsIsSupported" id="loading"-->
<!--           style="align-items: center; justify-content: center;width:100%;height:100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: RGB(120, 120, 120); font-size: 14px; padding: 10px; border-radius: 5px;">-->
<!--        <div style="display: flex;flex-direction: column">-->
<!--          <van-loading/>-->
<!--          <div>{{ $t('video.loading_resources') }}</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="item">
      <div class="title">
        <span>
          <i class="van-badge__wrapper van-icon" style="font-size: 22px;"><img
              class="van-icon__image"
              src="/img/movie/title_before.png">
        </i> {{ $t('video.recommended_videos') }}</span></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div aria-busy="true" class="van-list" role="feed">
          <ul class="hot">
            <li v-for="(item,index) in moreVideoInfo" :key="index" @click="toDetail(item)">
              <div class="van-image" style="width: 130px; height: 110px;">
                <img :src="item.vod_pic"
                     class="van-image__img"
                     style="object-fit: cover;"></div>
              <div class="r">
                <span>{{ item.vod_name }}</span>
                <div class="tps">
                  <a v-for="tag in getTag()" :key="tag">{{ tag }}</a>
                </div>
                <span><i class="van-badge__wrapper van-icon van-icon-play-circle"
                ></i>{{ Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000 }}{{
                    $t('video.played_times')
                  }}</span></div>
            </li>
          </ul>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import DPlayer from 'dplayer';
import Hls from 'hls.js';

export default {
  watch: {
    '$route.params.id'(newId) {
      // 当id变化时执行操作
      this.fetchData(newId);
    }
  },
  computed: {
    hlsIsSupported() {
      return Hls.isSupported()
    }
  },
  data() {
    return {
      dp: null,
      isPlaying: false, // 用于跟踪播放状态
      moreVideoInfo: [],
      tagList: [],
      info: {},
      isLoading: false,
      isVoucher: true,
      timer: null,
      trialTime: 180,
      elapsedTime: 0,
      isDuihuan: false,
      isLoad: false
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    onRefresh() {
      this.isLoading = true
      this.$http({
        method: 'get',
        url: 'video_get_more_item',
        data: {
          vod_class_id: this.info.vod_class_id
        }
      }).then(result => {
        this.moreVideoInfo = result.data
        this.isLoading = false
      })
    },
    toDetail(item) {
      const str = this.$objToString(item)
      this.$router.replace("/moviedetail?" + str)
      window.location.reload()
    },
    getTag() {
      let num = Math.floor((Math.random() * 4) + 2)
      let arr = []
      while (arr.length !== num) {
        let random = this.tagList[Math.floor(Math.random() * this.tagList.length)]?.name
        if (!arr.includes(random)) {
          arr.push(random)
        }
      }
      return arr
    }
  },
  async created() {
    this.info =  this.$route.query
    let {code} = await this.$http({
      method: 'post',
      url: 'duihuan_movie',
      data:{
        vid: this.info.id
      }
    })
    this.isDuihuan = (code === 200 || code === '200')
    const videoUrl = this.info.vod_play_url
    const hls = new Hls();
    if (Hls.isSupported()) {
      this.dp = new DPlayer({
        container: this.$refs.dplayer,
        video: {
          url: videoUrl, // 替换为实际视频链接
          pic: this.info.vod_pic,
          type: 'customHls', // 自定义类型
          customType: {
            customHls: (video) => {
              hls.loadSource(videoUrl);
              hls.attachMedia(video); // 手动将 HLS 附加到视频元素
            },
          },
        },
        autoplay: false,
      });
        if (!this.isDuihuan) {
          this.dp.on('play', () => {
            if (!this.timer) {
              this.timer = setInterval(() => {
                this.elapsedTime += 1;
                if (this.elapsedTime >= this.trialTime) {
                  this.isVoucher = false
                  this.dp.pause();
                  clearInterval(this.timer)
                  this.$toast(this.$t('video.insufficient_movie_tickets'))
                }
              }, 1000);
            }
            if (!this.isVoucher) {
              this.dp.pause();
              this.$toast(this.$t('video.insufficient_movie_tickets'))
            }
            this.isPlaying = true;
          });
        } else {
          this.dp.on('play', () => {
            this.isPlaying = true;
          })
        }
      this.dp.on('pause', () => {
        this.isPlaying = false;
      });
    } else {
      this.dp = new DPlayer({
        container: this.$refs.dplayer,
        video: {
          url: videoUrl, // 替换为实际视频链接
          pic: this.info.vod_pic,
          type: 'hls', // 自定义类型
          customType: {
            customHls: (video) => {
              if (Hls.isSupported()) {
                hls.loadSource(videoUrl);
                hls.attachMedia(video); // 手动将 HLS 附加到视频元素
              } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.addEventListener('canplay', function () {
                  this.player.play();
                });
              }
            },
          },
        },
        useNativeHls: true,
        autoplay: false,
      });
    }
    if (!this.isDuihuan) {
      this.dp.on('play', () => {
        if (!this.timer) {
          this.timer = setInterval(() => {
            this.elapsedTime += 1;
            if (this.elapsedTime >= this.trialTime) {
              this.isVoucher = false
              this.dp.pause();
              clearInterval(this.timer)
              this.$toast(this.$t('video.insufficient_movie_tickets'))
            }
          }, 1000);
        }
        if (!this.isVoucher) {
          this.dp.pause();
          this.$toast(this.$t('video.insufficient_movie_tickets'))
        }
        this.isPlaying = true;
      });
    } else {
      this.dp.on('play', () => {
        this.isPlaying = true;
      })
    }
    this.dp.on('pause', () => {
      this.isPlaying = false;
    });
    this.isLoad = true
    let  {data:tagData } =await  this.$http({
      method: 'get',
      url: 'video_get_tag_list',
    })
    this.tagList  = tagData
    let {data} = await this.$http({
      method: 'get',
      url: 'video_get_more_item',
      data: {
        vod_class_id: this.info.vod_class_id
      },
      isLoading: true
    })
    this.moreVideoInfo = data

  },
  beforeDestroy() {
    if (this.dp) {
      this.dp.destroy();
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>
<style lang="less" scoped>
.move {
  /deep/ .van-nav-bar {
    color: #000;

    .van-icon {
      color: #000;
      font-size: 20px;
    }

    .van-nav-bar__title {
      font-weight: 600;
    }
  }

  .item {
    width: 100%;
    padding: 1.33333rem .66667rem .66667rem;


    & > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .66667rem;

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;
        font-size: 1rem;
      }
    }

    .hot {
      width: 98%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 auto;

      li {
        width: 100%;
        border-radius: .2rem;
        overflow: hidden;
        margin-top: .46667rem;
        height: 6.66667rem;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .van-image {
          border-radius: 4px;
          overflow: hidden;

          .van-image__img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .r {
          flex: 1;
          padding-left: .66667rem;
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            white-space: nowrap;
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          & > :first-child {
            width: 10rem;
            color: #000;
            font-weight: 600;
            text-align: left;
          }

          .tps {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: -1.66667rem;

            a {
              margin: .16667rem .5rem 0 0;
              font-size: .73333rem;
              color: #fff;
              padding: .13333rem .33333rem;
              border-radius: .33333rem;

              &:nth-child(1n) {
                background: #f6287e;
              }

              &:nth-child(2n) {
                background: #fa9a0a;
              }

              &:nth-child(3n) {
                background: #0aa5ff;
              }
            }
          }

          :last-child {
            display: flex;
            color: #777;
            font-size: .73333rem;
            align-items: flex-end;

            i {
              margin-right: .33333rem;
              font-size: 16px;
              font-style: normal;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              display: inline-block;

              :before {
                display: inline-block;
              }
            }
          }
        }
      }
    }

  }
}

</style>