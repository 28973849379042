<template>
  <div class="jfdetail">
    <Head :title="$t('setting.exchange_record')"></Head>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div aria-busy="false" class="van-list" role="feed">
        <div class="van-cell-group van-hairline--top-bottom">
          <div v-for="(item,index) in list" :key="index" class="van-cell">
            <div class="van-cell__title">
              <div class="box">
                <div class="left">提现</div>
                <div><p style="color: rgb(41, 41, 41);">{{ item.status_text }}</p><span class="sp1"
                >{{ item.create_time }}</span><span
                    class="sp2"
                    style="display: block; color: rgb(135, 135, 135); font-size: 12px;">{{ item.order_no }}</span>
                </div>
              </div>
            </div>
            <div class="van-cell__value"><span style="color: rgb(242, 96, 96);">-{{ item.money }}</span></div>
          </div>
        </div>
        <div class="van-list__placeholder"></div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      isLoading: false,
      list: []
    }
  },
  methods: {
    onRefresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 3000)
    }
  },
  created() {
    this.$http({
      method: 'get',
      url: 'user_get_withdraw_list',
      isLoading: true,
    }).then(res => {
      this.list = res.data
    })
  }
}
</script>

<style lang="less" scoped>
.jfdetail {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-cell-group {
    width: 100%;
    margin: 10px auto;
    background: none;
    position: relative;

    .van-cell {
      margin-bottom: 8px;

      .van-cell__title {
        width: 80%;
        flex: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #a9a7b4;
        font-size: 14px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .left {
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #fa67a3, #f32a7a);
            margin-right: 10px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
          }

          p {
            font-weight: 600;
          }

          .sp1 {
            font-size: 12px;
          }

          .sp2 {
            color: #9c91c0 !important;
          }
        }
      }

      .van-cell__value {
        color: #f26060;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600;
        flex: auto;
        position: relative;
        overflow: hidden;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
  }
}

</style>