<template>
  <div class="jfdetail">
    <Head :title="$t('setting.data_record')">
      <i class="van-badge__wrapper van-icon" style="font-size: 16px;" @click="show = !show">
        <img class="van-icon__image" src="/img/mine/shaixuan.png">
      </i>
    </Head>
    <div id="container" class="tabs__wrap">
      <van-tabs v-model="active" @change="changeTabs">
        <van-tab :name="0" :title="$t('setting.all')"></van-tab>
        <van-tab :name="2" :title="$t('setting.points_earned')"></van-tab>
        <van-tab :name="1" :title="$t('setting.to_be_announced')"></van-tab>
        <van-tab :name="3" :title="$t('setting.notWinning')"></van-tab>
      </van-tabs>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('setting.no_more')"
            @load="getList"
        >
          <van-cell v-for="(item,index) in list" :key="index">
            <div class="van-cell__title">
              <p :style="{background: item.is_win=== 0 ? 'linear-gradient(0deg, rgb(65, 57, 60), rgb(102, 92, 96)' :item.is_win === 1 ? 'linear-gradient(0deg, rgb(104, 236, 110), rgb(9, 149, 61))'
               :'linear-gradient(0deg, rgb(250, 106, 165), rgb(243, 40, 121))'}">
                {{
                  item.is_win === 0 ? $t('setting.to_be_drawn') : item.is_win === 1 ? $t('setting.winner') : $t('setting.notWinning')
                }}</p>
              <div>
                <span class="custom-title">{{ item.title }}</span>
                <span>{{ $t('setting.the_first') + item.expect + $t('setting.period') }}</span>
                <span>{{ $t('setting.betting_content') }}:{{ item.type_text }}</span></div>
            </div>
            <div class="van-cell__value">
              <span style="font-size: 16px;">{{ item.is_win === 0 ? item.total_bet_amount : item.total_profit }}</span>
            </div>
            <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"></i>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="show" :style="{ top:'46px'}" position="top">
      <span v-for="(item,index) in filterList" :key="index" @click="clickfilter(item.id)">
        <a :class="item.id === lid ? 'active' : ''">{{ item.name }}</a>
      </span>
    </van-popup>
  </div>
</template>
<script>

import Head from "@/pages/mine/head.vue";

export default {
  components: {Head},
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      active: '',
      list: [],
      show: false,
      filterList: [],
      lid: ''
    }
  },
  methods: {
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.isLoading = true
      this.getList();
    },
    changeTabs(e) {
      this.active = e
      this.getList()
    },
    getList() {
      if (this.isLoading) {
        this.list = [];
        this.isLoading = false;
      }
      this.$http({
        method: 'get',
        url: 'user_get_game_list',
        isLoading: true,
        data: (this.active === 0) ?
            {
              lid: this.lid,
            } : {
              lid: this.lid,
              is_win: this.active - 1
            }
      }).then(res => {
        this.list = res.data
      })

      this.loading = false;

      this.finished = true;
    },
    clickfilter(lid) {
      if (this.lid === lid) {
        this.lid = ''
      }
      this.lid = lid
      this.show = false
      this.getList()
    }
  },
  mounted() {
    this.$http({
      method: 'get',
      url: 'lottery_class',
    }).then(res => {
      this.filterList = res.data
    })
  },
  created() {
    this.list = []
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-popup {
  padding: 5%;
  background: #fff;

  span {
    width: 33.3333%;
    float: left;
    margin-bottom: .66667rem;

    a {
      border: 1px solid #b8b8b8;
      width: 90%;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #778283;
      border-radius: .33333rem;
    }

    a.active {
      background: #f53d87;
      color: #fff;
      border: none;
    }
  }
}

/deep/ .van-tabs__nav {
  height: 100%;
  box-sizing: content-box !important;

  .van-tab {
    color: rgb(40, 33, 31)
  }

  .van-tab--active {
    font-size: 16px;
    font-weight: 600;
    color: #f42f7e;
  }

  .van-tabs__line {
    height: 4px;
    background-color: rgb(244, 47, 126);
    border-radius: 0 !important;
  }
}

/deep/ .van-cell {
  margin-bottom: 6px;

  .van-cell__value--alone {
    display: flex;
    align-items: center;

    .van-cell__title {
      flex: auto;
      width: 60%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #a9a7b4;

      & > p {
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        color: #fff;
        font-size: 12px;
        margin-right: 10px;
      }

      & > div {
        display: flex;
        flex-direction: column;

        .custom-title {
          font-size: 1rem;
          color: #292929;
        }
      }
    }

    .van-cell__value {
      flex: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: block;
        color: #292929;
        font-weight: 600;
      }
    }

    .van-cell__right-icon {
      color: #a9a1c3;
    }
  }
}

.jfdetail {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .tabs__wrap {
    height: 44px;
  }

  .main {
    margin-top: 10px
  }

  .van-cell-group {
    width: 100%;
    margin: 10px auto;
    background: none;
    position: relative;

    .van-cell {
      margin-bottom: 8px;

      .van-cell__title {
        width: 80%;
        flex: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        color: #a9a7b4;
        font-size: 14px;

        .box {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .left {
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, #fa67a3, #f32a7a);
            margin-right: 10px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
          }

          p {
            font-weight: 600;
          }

          .sp1 {
            font-size: 12px;
          }

          .sp2 {
            color: #9c91c0 !important;
          }
        }
      }

      .van-cell__value {
        color: #f26060;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 600;
        flex: auto;
        position: relative;
        overflow: hidden;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
  }
}

</style>