<template>
  <div class="info">
    <Head :title="$t('mine.basic_info')"></Head>
    <div class="van-cell-group van-hairline--top-bottom">
      <div class="van-cell van-cell--clickable" role="button" tabindex="0" @click="editUsername">
        <div class="van-cell__title"><span>{{ $t('mine.account_name') }}</span></div>
        <div class="van-cell__value"><span>{{ userInfo.username }}</span></div>
        <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <div class="van-cell van-cell--clickable" role="button" tabindex="0" @click="goRouter('/changename')">
        <div class="van-cell__title"><span>{{ $t('mine.real_name') }}</span></div>
        <div class="van-cell__value"><span>{{ userInfo.name ? userInfo.name : '' }}</span></div>
        <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <div class="van-cell van-cell--clickable" role="button" tabindex="0" @click="goRouter('/bind')">
        <div class="van-cell__title"><span>{{ $t('mine.bind_bank_card_number') }}</span></div>
        <div class="van-cell__value"><span>{{ isBank ? bankInfo.bankid : $t('setting.unbound') }}</span></div>
        <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
      <div class="van-cell van-cell--clickable" role="button" tabindex="0" @click="goRouter('/changenumber')">
        <div class="van-cell__title"><span>{{ $t('mine.phone_number') }}</span></div>
        <div class="van-cell__value"><span>{{ userInfo.phone ? userInfo.phone : $t('setting.unbound') }}</span></div>
        <i class="van-badge__wrapper van-icon van-icon-arrow van-cell__right-icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Head from './head.vue'

export default {
  components: {
    Head
  },
  data() {
    return {
      userInfo: {},
      isBank: false,
      bankInfo: {}
    };
  },
  methods: {
    editUsername() {
      this.$toast(this.$t('mine.cannot_be_modified'))
    },
    goRouter(route) {
      this.$router.push({path: route});
    }
  },
  created() {
    this.$http({
      method: 'get',
      url: 'user_info',
    }).then(res => {
      this.userInfo = res.data
      localStorage.setItem('userInfo', JSON.stringify(res.data))
    })
    this.$http({
      method: 'get',
      url: 'user_get_bank',
    }).then(res => {
      this.isBank = res.data?.is_bank
      if (res.data.info) {
        this.bankInfo = res.data.info
      }
    })
  }
};
</script>

<style lang='less' scoped>
.van-cell--clickable {
  cursor: pointer;
}

.info {
  width: 100%;
  height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-cell-group {
    background: none;
    position: relative;

    .van-cell {
      padding: 20px 20px;
      margin-top: 5px;
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      color: var(--van-cell-text-color);
      font-size: var(--van-cell-font-size);
      line-height: var(--van-cell-line-height);
      background: var(--van-cell-background-color);

      .van-cell__title, .van-cell__value {
        flex: 1;
      }

      .van-cell__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;
        text-align: left;
      }

      .van-cell__value {
        position: relative;
        overflow: hidden;
        color: #b0b0b0;;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
  }
}
</style>
