<template>
  <div class="bg">
    <div class="register">
      <div class="head">
        <i class="van-badge__wrapper van-icon van-icon-close" @click="goBack"
        ></i>
        <a @click="goLogin">{{ $t('auth.login2') }}</a>
      </div>
      <img alt="" src="/img/login/logo-title.png">
      <form>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon"
             style="color: rgb(153, 153, 153); font-size: 20px;"><img
              class="van-icon__image"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAw9JREFUOE+V01toE1kcx/HvmSSN0WrVtK7WBVeNvtRWKypIBS/1Qenuy0K01suLVMVbJRW1ijXbCNJ6w1tFV3ZVUNRQBUXwUiuiFsGKom1fvCAIjbcg8ZaaJnPkzHSaRgPL/l9mzgznM7//OWcEgAShrv+3hDE1WeIn6Nw5jV+nZjAxZzgO5xJi8UZq7zTjv6WDP2WyxVhoL8xvppPbNgILkfI9unyGTRsJuIxxOLqcnJ1v0qEK7Ma6oedr+zNyYDOJRCVlZddT2q6v/4Xs7GvMC44n2N6dMDWpkPi1nkmdmzw4nQeY+VdJ2vW7uS3E48e/sX17J8E8ifq4W9NIJBK43Z+SmLdNcPz4eFyuaiav8KbF7h8JcffJGPYHPhrvz569ixBZQCdCTDQxBb3LEzRu/RtEJgU1C9NirdXtSHma4kCN8T6nzWxXpQSE9HptxgOFXdlSQSR6j6ItLemT1Zbg0L8w3ddE/1wT6gWamIJUXa76Hbs2iTxfgLg79ezlD9O4UH6KV6+rKDn6AleHTAGDeTKJfeoQDBmncWnVSS62rsF3wVwXVcMighs7lqGLd0xY30CGW/Jg83zsYhSaphJ+oLS0PhWL5goa1+UzdMABiiqLU1pt3hPm8OXh1LVEsYcl/6wdQF+HHZeUOF06VWvCQs7w241JKlnEqRnttfjmMDiziqLKGRR6bBxa2cGdpxPYcDhEKEsamCqV0Go3p02amIJUqlhYGFjrxgXYtLHM2mTu2u3dTbx8W87i2ufG2AItTD0b9UFPYvs3DGLSiJU4bH8Si1+nfF817V/MBEuLnZRPPYamjeBr7BiBq6c43xj7MZ2Ql1r6MrfwIJoYQiR6lKehh1Qcek8sK7mbXZ/N+xMVuYzOnkY/52q+JU5T4DvIozovfTJKmB1YLGQ8cZKu+BXy1zcYLaqd6w0pxMIcmWbSPzx2NpfuIha7yZS6BmvdhNT1JsbVzOlZr//CMiIm6CnU+HfRCQpqypJYV9dehM2D9bvruqBn0H04pC4QxnkCdPOiztfXb2eYUhu0sO93BVYkZoYTLAAAAABJRU5ErkJggg==">
          </i>
          <input v-model="username" :placeholder="$t('auth.member_ccount_place')" type="text">
        </div>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon"
             style="font-size: 20px;">
            <img class="van-icon__image"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAAAXNSR0IArs4c6QAAAoxJREFUOE91k19IFFEUxr87M+66jlqkCxEVST0EQpgGuSC4RWYlUoorpYX20kOUIYhLWbJIliFWGEXUiyKBqGVimFmRkPknCSswQmlJol62zNx13V1n5sSdcddUui/3D+d3vu/cey4jgCE8HG0CHhzZBUm6BMa2ARBA9BkB9RpKOofRXqiFQ5kBugx4vsqOaKkRoVAVPJ4B/cy6PgMmsQYBpRyW2n4DdBEjuAR9/eVEHJKSniOgnsOh2tGIC77oqkxDXHQjpqaykdQ8y48M0DHO0NS0ExbLTdQ070P3kLoMzLWJqC59gYDiREnRMNqTiZHDIcKTzPDEmYlocyV21OQi9Gupbp7BlED4WN2NYOgWcup6YR0nRnaXBO8PhpfXMxEvV+DwvfxlauFN16lH8PlvY095L+I2EKNXY2uRnpyHKCELgmCHovXxEkCaocoE4ncLQdgPYABB5RnGJjsZaVoPNPqJuWAPCATGRKgqA8RFLRUQRQKRCgYGiykbgrCJg250vN+NGy0+hNYwLPh0JY8Sq89WyUd6hqhYgukP4WyxjGNpI4w0cuPxYDrqO7xhkEPr+soyuYPfR++/TvR6tAh4oVhGTuqQAbaO2NDQ6+VqHLLWFybgwPZPek/1T6R6zrR+15W5qnOvjIKMwSWwrsMXtme2bTTF3y19CAbR62zLDzydCERsV+TJOG57swpkql+vLfHD5QYQTNP2K2XajJ9IjCFd9X+g/gKqnyWOuE5DENl01tU7qhd6c68ANTda39rwj1UORqVtkXjwwruvCleLgBeLYlCQwmvUJvFt5iR6RifmJNF49FBwRcuZdVBWVMLBlK3YbG1hNDtfAtl8HuAfbHn8qtajxZj5YMNfzQA5+DtACFwAAAAASUVORK5CYII=">
          </i>
          <input v-model="password" :placeholder="$t('auth.login_pwd_place')" :type="isShow? 'text':'password'"
                 autocomplete="">
          <!--          <i-->
          <!--              class="van-badge__wrapper van-icon van-icon-closed-eye"-->
          <!--              style="color: rgb(151, 151, 153); font-size: 20px;"-->
          <!--              @click="isShow=!isShow"></i>-->
          <van-icon v-show="isShow" color="rgb(151,151,153)" name="eye-o" size="20px" @click="isShow=!isShow"/>
          <van-icon v-show="!isShow" color="rgb(151,151,153)" name="closed-eye" size="20px" @click="isShow=!isShow"/>
        </div>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon"
             style="font-size: 20px;">
            <img class="van-icon__image"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAAAXNSR0IArs4c6QAAAuVJREFUOE91k1toVEcYx//fnHP2GrMmMbKx+tZKILTBpK02IogBfbBoq6zVICqFIvEhMWmjNWrdWsUYUVHwgi9KUR9cCGgpPlQx4jX1VsUQ8BLJxaRNYrKbbHJyzs7OV87ZWKHqvMx/vvnmN/9vLsQAAVHC6xZpfaP/C06IWBG/CUVdTYyowGh9KQLGn//Pf+9YyjIYu1sygEhEw6kzJQgYV9GbLAWBYNsZFz4jhPFUwtUeD4MtRkHeTZhyMdZWtCBWxMTzozrObypBtv8KPqrKdZObfvgAx1v6cCQygs0XsjEn7MGm00l37smhXsSTS7DswG3ktzLxpbY8zJrxKXKDTSirnQI7RLix7RB0MQMaLYRlN0I3ylFzthx376VwY/9LxM01eNp5CxsODhMzWwAMACaK66b2yyzSiwq00Mm1O0XQWwXG37j4YE5/3YVX+XqS8VdjF4hyAEj0xD93ADbG7J8R8NQPFG4Oez4JG9lnq35TlmwRQe/GdPfAai2csy7x1b5vUs+G5JS2vR0w7QPwG/UYHP3SAaQBKDCnrXO3isyGP155F36cJdtejE+KVZ9Ifn1sfbq7R7LmY1/l3Gzfd+WPQRRwz+OfxCJipcbk485yvXB6IwytGENjDcPz9h2noEVIewmaxTzq5azm2m8pL2srpdUT+aKvTv8w3IT+4ZUOwBz6bEtYnxzSA79WLtXCoV0Am+n2vh/NFYeb/acrv9BmTm9gwfk0MLJ9pOJoTMYTMudOQ7vsHcwA4iU7C0QKwtlVn1vo8/4UqRUhfzXb8jp59Xkqbh61fjnfKK8/GnPcKANq8v0dz/EakCiunyb8JBzLpDLWPd8vCHuWz65JXW49YkVjHU7JLLzslKRMVqGHe57J7sFVroO3AE62C7OJhcdd9E5AR1+F+w7UwMhWAZBSzscChHMtE9odC7Ca6N3TF0IhNxiVXYPLiVPydwBBdwdmev9XBBwbRJRxo3jcvta24V9d9Gm+o8lOZQAAAABJRU5ErkJggg==">
          </i>
          <input v-model="invitation_code" :placeholder="$t('auth.invite_code_place')" type="text">
        </div>
      </form>
      <div class="res">
        <div aria-checked="true" class="van-checkbox" role="checkbox" tabindex="0">
          <div class="van-checkbox__icon van-checkbox__icon--round van-checkbox__icon--checked"
               style="font-size: 16px;">
            <i class="van-badge__wrapper van-icon van-icon-success"
               style="border-color: rgb(244, 49, 127); background-color: rgb(244, 49, 127);">
            </i>
          </div>
        </div>
        <a>{{ $t('auth.terms_of_service') }}</a>
      </div>
      <div :class="isEnter ?'btn active' :'btn'" @click="doLogin">{{ $t('auth.register') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      invitation_code: "",
      isShow: false
    };
  },
  computed: {
    isEnter() {
      if (this.username === '' || this.password === '') {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goLogin() {
      this.$router.replace('/login')
    },
    doLogin() {
      if (this.isEnter) {
        let reg = /^[A-Za-z]+/
        if (reg.test(this.username)) {
          this.$http({
            url: "member_register",
            method: "post",
            data: {
              username: this.username,
              password: this.password,
              code: this.invitation_code,
            },
          }).then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.msg);
              this.$router.push("/Login");
            } else {
              this.$toast(res.msg);
            }
          });
        } else {
          this.$toast.fail('使用者名稱必須以英文開頭')
        }

      }

    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
.bg {
  height: 100%;
  background-image: linear-gradient(180deg, #f560cd, #4f2b8a);

  .register {
    width: 100%;
    height: 100%;
    background: url(/img/login/login-bg.png) no-repeat 50% / 100%;
    padding-bottom: 3.33333rem;
    position: relative;
    z-index: 1;

    & > * {
      position: relative;
      z-index: 9;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(30deg, rgba(125, 118, 239, .25), rgba(225, 70, 149, .18));
      left: 0;
      top: 0;
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4% 3%;

      i {
        font-size: 1.66667rem;
        color: #fff;
      }

      a {
        font-size: 1.06667rem;
        color: #fff;
        font-weight: 400;
      }
    }

    & > img {
      width: 12rem;
      margin-top: 6rem;
    }


    .ipt {
      width: 90%;
      height: 3.66667rem;
      margin: 1rem auto;
      border-radius: .33333rem;
      overflow: hidden;
      background: rgba(79, 79, 79, .8);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.66667rem;

      i {
        margin-right: .66667rem;

        .van-icon__image {
          display: block;
          width: 1em;
          height: 1em;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      input {
        flex: 1;
        height: 100%;
        border: none;
        background: none;
        outline: none;
        text-align: left;
        font-size: .93333rem;
        padding-left: .33333rem;
        color: #fff;
      }

      input::placeholder {
        font-size: 1rem;
        color: hsla(0, 0%, 100%, .81)
      }
    }

    .forget {
      width: 100%;
      text-align: right;
      padding: 0 1.33333rem;
    }

    .res {
      width: 100%;
      padding: 0 1.33333rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .van-checkbox {
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      a {
        color: #fff;
        font-size: .8rem;
        margin-left: .33333rem;
      }
    }

    .btn {
      width: 90%;
      margin: 1.66667rem auto;
      background: #cacacc;
      height: 2.93333rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: .33333rem;
      font-size: 1.06667rem;
    }

    .btn.active {
      background: linear-gradient(180deg, #fc78af, #f22678);
    }
  }
}
</style>
