<template>
  <div class="bg">
    <div class="login">
      <div class="head">
        <i class="van-badge__wrapper van-icon van-icon-close" @click="goBack"></i>
        <van-popover
            v-model="show"
            :actions="list"
            trigger="click"
            @select="onSelect"
        >
          <template #reference>
            <van-button style="color: #fff;background:none">{{ curLang }}
            </van-button>
          </template>
        </van-popover>
      </div>
      <img alt="" src="/img/login/logo-title.png">
      <form>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon" style="color: rgb(153, 153, 153); font-size: 20px;">
            <img class="van-icon__image"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAASCAYAAAC5DOVpAAAAAXNSR0IArs4c6QAAAw9JREFUOE+V01toE1kcx/HvmSSN0WrVtK7WBVeNvtRWKypIBS/1Qenuy0K01suLVMVbJRW1ijXbCNJ6w1tFV3ZVUNRQBUXwUiuiFsGKom1fvCAIjbcg8ZaaJnPkzHSaRgPL/l9mzgznM7//OWcEgAShrv+3hDE1WeIn6Nw5jV+nZjAxZzgO5xJi8UZq7zTjv6WDP2WyxVhoL8xvppPbNgILkfI9unyGTRsJuIxxOLqcnJ1v0qEK7Ma6oedr+zNyYDOJRCVlZddT2q6v/4Xs7GvMC44n2N6dMDWpkPi1nkmdmzw4nQeY+VdJ2vW7uS3E48e/sX17J8E8ifq4W9NIJBK43Z+SmLdNcPz4eFyuaiav8KbF7h8JcffJGPYHPhrvz569ixBZQCdCTDQxBb3LEzRu/RtEJgU1C9NirdXtSHma4kCN8T6nzWxXpQSE9HptxgOFXdlSQSR6j6ItLemT1Zbg0L8w3ddE/1wT6gWamIJUXa76Hbs2iTxfgLg79ezlD9O4UH6KV6+rKDn6AleHTAGDeTKJfeoQDBmncWnVSS62rsF3wVwXVcMighs7lqGLd0xY30CGW/Jg83zsYhSaphJ+oLS0PhWL5goa1+UzdMABiiqLU1pt3hPm8OXh1LVEsYcl/6wdQF+HHZeUOF06VWvCQs7w241JKlnEqRnttfjmMDiziqLKGRR6bBxa2cGdpxPYcDhEKEsamCqV0Go3p02amIJUqlhYGFjrxgXYtLHM2mTu2u3dTbx8W87i2ufG2AItTD0b9UFPYvs3DGLSiJU4bH8Si1+nfF817V/MBEuLnZRPPYamjeBr7BiBq6c43xj7MZ2Ql1r6MrfwIJoYQiR6lKehh1Qcek8sK7mbXZ/N+xMVuYzOnkY/52q+JU5T4DvIozovfTJKmB1YLGQ8cZKu+BXy1zcYLaqd6w0pxMIcmWbSPzx2NpfuIha7yZS6BmvdhNT1JsbVzOlZr//CMiIm6CnU+HfRCQpqypJYV9dehM2D9bvruqBn0H04pC4QxnkCdPOiztfXb2eYUhu0sO93BVYkZoYTLAAAAABJRU5ErkJggg==">
          </i>
          <input v-model="username" :placeholder="$t('auth.member_ccount_place')" type="text">
        </div>
        <div class="ipt">
          <i class="van-badge__wrapper van-icon"
             style="font-size: 20px;">
            <img class="van-icon__image"
                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAARCAYAAADtyJ2fAAAAAXNSR0IArs4c6QAAAoxJREFUOE91k19IFFEUxr87M+66jlqkCxEVST0EQpgGuSC4RWYlUoorpYX20kOUIYhLWbJIliFWGEXUiyKBqGVimFmRkPknCSswQmlJol62zNx13V1n5sSdcddUui/3D+d3vu/cey4jgCE8HG0CHhzZBUm6BMa2ARBA9BkB9RpKOofRXqiFQ5kBugx4vsqOaKkRoVAVPJ4B/cy6PgMmsQYBpRyW2n4DdBEjuAR9/eVEHJKSniOgnsOh2tGIC77oqkxDXHQjpqaykdQ8y48M0DHO0NS0ExbLTdQ070P3kLoMzLWJqC59gYDiREnRMNqTiZHDIcKTzPDEmYlocyV21OQi9Gupbp7BlED4WN2NYOgWcup6YR0nRnaXBO8PhpfXMxEvV+DwvfxlauFN16lH8PlvY095L+I2EKNXY2uRnpyHKCELgmCHovXxEkCaocoE4ncLQdgPYABB5RnGJjsZaVoPNPqJuWAPCATGRKgqA8RFLRUQRQKRCgYGiykbgrCJg250vN+NGy0+hNYwLPh0JY8Sq89WyUd6hqhYgukP4WyxjGNpI4w0cuPxYDrqO7xhkEPr+soyuYPfR++/TvR6tAh4oVhGTuqQAbaO2NDQ6+VqHLLWFybgwPZPek/1T6R6zrR+15W5qnOvjIKMwSWwrsMXtme2bTTF3y19CAbR62zLDzydCERsV+TJOG57swpkql+vLfHD5QYQTNP2K2XajJ9IjCFd9X+g/gKqnyWOuE5DENl01tU7qhd6c68ANTda39rwj1UORqVtkXjwwruvCleLgBeLYlCQwmvUJvFt5iR6RifmJNF49FBwRcuZdVBWVMLBlK3YbG1hNDtfAtl8HuAfbHn8qtajxZj5YMNfzQA5+DtACFwAAAAASUVORK5CYII=">
          </i>
          <input v-model="password" :placeholder="$t('auth.pwd_place')" :type="isShow ? 'text' :'password'"
                 autocomplete="">
          <van-icon v-show="isShow" color="rgb(151,151,153)" name="eye-o" size="20px" @click="isShow=!isShow"/>
          <van-icon v-show="!isShow" color="rgb(151,151,153)" name="closed-eye" size="20px" @click="isShow=!isShow"/>
        </div>
      </form>
      <div class="forget"></div>
      <div class="res">
        <a @click="toRegister">{{ $t('auth.no_account') }}</a>
      </div>
      <div :class="isEnter ?'btn active' :'btn'" @click="doLogin">{{ $t('auth.login') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      show: false,
      list: [
        {text: '中文繁体', value: 'zh_cn'},
        {text: 'English', value: 'en_us'},
        {text: '한국인', value: 'ko_kr'},
        {text: '日本語', value: 'ja_jp'},
        {text: 'Tiếng Việt', value: 'vi_vn'}
      ],
      isShow: false
    };
  },

  computed: {
    lang() {
      return localStorage.getItem('lang') ? localStorage.getItem('lang'): process.env.VUE_APP_DEFAULT_LANG
    },
    curLang() {
      return this.list.filter(item => item.value === this.lang)[0]['text']
    },
    isEnter() {
      if (this.password === "" || this.password === null || this.password === undefined
          || this.username === "" || this.username === null || this.username === undefined) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    toRegister() {
      this.$router.push('/res')
    },
    onSelect(e) {
      localStorage.setItem('lang', e.value)
      window.location.reload()
    },
    goBack() {
      this.$router.push('/Home')
    },
    doLogin() {
      if (!this.isEnter) {
        this.$toast(this.$t("auth.incomplete_info"));
        return false;
      }

      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data);
          this.$router.push("/Mine");
        } else if(res.code === 505) {
          this.$toast.fail(this.$t('setting.network_error'))
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
.bg {
  background-image: linear-gradient(180deg, #f560cd, #4f2b8a);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  .login {
    width: 100%;
    height: 100%;
    background: url(/img/login/login-bg.png) no-repeat 50% / 100%;
    position: relative;
    z-index: 99;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4% 3%;

      i {
        font-size: 1.66667rem;
        color: #fff;
      }
    }

    & > img {
      width: 12rem;
      margin-top: 3.33333rem;
      margin-bottom: 1.9rem;
    }

    .ipt {
      width: 90%;
      height: 3.66667rem;
      margin: 1rem auto;
      border-radius: .33333rem;
      overflow: hidden;
      background: rgba(79, 79, 79, .8);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 1.33333rem;

      i {
        margin-right: .66667rem;

        .van-icon__image {
          display: block;
          width: 1em;
          height: 1em;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      input {
        flex: 1;
        height: 100%;
        border: none;
        background: none;
        outline: none;
        text-align: left;
        font-size: .93333rem;
        padding-left: .33333rem;
        color: #fff;
      }

      input::placeholder {
        font-size: 1rem;
        color: hsla(0, 0%, 100%, .81)
      }
    }

    .forget {
      width: 100%;
      text-align: right;
      padding: 0 1.33333rem;
    }

    .res {
      width: 100%;
      padding: 0 1.33333rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        color: #fff;
        font-size: .93333rem;
      }

      a:last-child {
        padding: .26667rem .66667rem;
        margin-top: .66667rem;
        border-radius: .13333rem;
      }
    }

    .btn {
      width: 90%;
      margin: 1.66667rem auto;
      background: #cacacc;
      height: 2.93333rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: .33333rem;
      font-size: 1.06667rem;
    }

    .btn.active {
      background: linear-gradient(180deg, #fc78af, #f22678);
    }
  }
}
</style>
