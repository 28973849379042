<template>
  <div class="index">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left van-haptics-feedback">
          <i class="van-badge__wrapper van-icon" style="font-size: 28px;">
            <van-badge :content="messageCount" v-if="messageCount > 0">
            <img class="van-icon__image" src="/img/home/info.png" @click="goNotion">
            </van-badge>
            <img v-else class="van-icon__image" src="/img/home/info.png" @click="goNotion">
          </i></div>
        <div class="van-nav-bar__title van-ellipsis">
          <i class="van-badge__wrapper van-icon"
             style="font-size: 108px;">
            <img class="van-icon__image"
                 src="/img/home/logo.png">
          </i></div>
        <div class="van-nav-bar__right van-haptics-feedback">
          <i class="van-badge__wrapper van-icon"
             style="font-size: 28px;">
            <img class="van-icon__image" src="/img/home/mine.png" @click="toRouter('/Mine')">
          </i></div>
      </div>

    </div>
    <van-notice-bar
        :text="notice"
        color="rgb(23, 29, 58)"
        left-icon="/img/home/notify.png"
    />
    <div ref="mySwiper" class="my-swiper">
      <van-swipe :autoplay="3000" :show-indicators="false" class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image.url" class="swpier-img"/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="card">
      <div class="tp">
        <div><i class="van-badge__wrapper van-icon" style="font-size: 108px;">
          <img class="van-icon__image" src="/img/home/card_logo.png"></i></div>
        <div>
          {{ $t('home.tips1') }}
        </div>
      </div>
      <div class="ft">
        <div>
          {{ $t('home.tips2') }}
        </div>
      </div>
    </div>
    <div class="yixianqian">
      <nav>
        <a :class="active === 0? 'active':''" @click="setActive(0)">{{ $t('home.platform_resources') }}</a>
        <a :class="active === 1? 'active':''" @click="setActive(1)">{{ $t('home.instant_pairing') }}</a>
        <a :class="active === 2? 'active':''" @click="setActive(2)">{{ $t('home.terms_of_service') }}</a>
      </nav>
      <transition name="fade">
        <div v-show="active === 0" class="list">
          <div v-for="(item,index) in dataList" :key="index" class="slide" @click="toBeautys(item.id)">
            <div class="image">
              <img :src="item.img_url"
                   alt=""></div>
            <div class="box">
              <div class="tps"><p>{{ item.xuanfei_name }} <em><i
                  class="van-badge__wrapper van-icon">
                <img class="van-icon__image"
                     src="/img/dingwei.png">
              </i> {{ item.address }}</em></p>
                <div class="rz"><img src="/img/home/rz.png"></div>
                <span v-for="(info,idx) in item.tag_name" :key="idx">{{ info }}</span>
              </div>
              <div class="pj">
                <div class="wx">
                  <i v-for="e in  Array.from({ length: Math.floor(item.rating) })" :key="e"
                     class="van-badge__wrapper van-icon" style="font-size: 18px;">
                    <img class="van-icon__image" src="/img/beauty/money.png">
                  </i>
                </div>
              </div>
              <a>{{ $t('home.i_want_to_date_her') }}</a></div>
          </div>
        </div>
      </transition>
      <div v-show="active === 1" class="gundong" style="display: none;">
        <p>
          {{ $t('home.at_present') + random + $t('home.pairing_online') }}</p>
        <swiper v-if="yuyueList.length" ref="swiperRef" :options="peiduiSwiperOption" data-v-text>
          <swiper-slide v-for="(item, index) in yuyueList" :key="index">
            <div>{{ item }}</div>
          </swiper-slide>
        </swiper>

      </div>
      <div v-show="active === 2" class="tk" style="display: none;">
        {{ $t('home.terms_of_service_value') }}
      </div>

    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';

export default {
  data() {
    return {
      active: 0,
      animationTime: 12,
      carouselInterval: null,
      carouselInterval2: null,
      interval: 12000,
      width: 0,
      textWidth: 0,
      images: [],
      notice: '',
      peiduiSwiperOption: {
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 5,
        speed: 800,
        loop: true,
        direction: 'vertical',
        autoplay: {
          delay: 2000,
        },
      },
      yuyueList: [],
      dataList: [],
      random: 0,
      messageCount: 0,
      timer: null
    };
  },
  methods: {
    getyuyueList() {
      this.$http({
        method: 'get',
        url: 'yuyue_number',
      }).then(res => {
        this.yuyueList = res.data
      })
    },
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'sys_config',
      }).then(res => {
        this.images = res.data.banners
        this.notice = res.data.notice
      })
    },
    goNotion() {
      this.$router.push({path: "/notion", query: {type: 1}})
    },
    toRouter(route) {
      this.$router.push({path: route})
    },
    toBeautys(id) {
      this.$router.push('/beautys?id=' + id)
    },
    setActive(index) {
      this.active = index
      if (index === 1) {
        this.random = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;
        this.getyuyueList()
      }
    },
    getList() {
      this.$http({
        method: 'get',
        url: 'xuanfei',
      }).then(res => {
        this.dataList = res.data
      })
    },
    getNotion() {
      if (localStorage.getItem('token')) {
        this.$http({
          method: 'get',
          url: 'user_info',
        }).then(res => {
          if (res.code === 200) {
            this.messageCount = res.data['message_count']
            localStorage.setItem('userInfo', JSON.stringify(res.data))
          }
        })
        this.timer = setInterval(()=>{
          this.$http({
            method: 'get',
            url: 'user_info',
          }).then(res => {
            if (res.code === 200) {
              this.messageCount = res.data['message_count']
              localStorage.setItem('userInfo', JSON.stringify(res.data))
            }
          })
        },5000)
      }
    }
  },
  created() {
    this.getNotion()
    this.getBaseInfo()
    this.getList()
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  }
}

</script>

<style lang='less' scoped>
/deep/ .van-badge {
  border: none;
}
.fade-enter-active, .fade-leave-active {
  transition: all 1s linear;
}

.fade-enter, .fade-leave-to {
  transition: opacity 0s linear;
  opacity: 0;
}

/deep/ .van-notice-bar__left-icon {
  position: relative;
  padding-right: 14px;
  font-size: 16px;
}

/deep/ .van-notice-bar__content {
  font-size: 13px
}

.index {
  width: 100%;
  padding-top: .5rem;
  padding-bottom: 4.33333rem;
  background: url(/img/home/home_bgc.png) repeat top / 100% auto;

  .van-icon__image {
    display: block;
    width: 1em;
    height: 1em;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .van-icon {
    font-size: 16px;
    color: #fff;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .van-nav-bar {
    z-index: 999;
    background: none;
    position: relative;
    line-height: var(--van-line-height-lg);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .van-nav-bar__content {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--van-nav-bar-height);

      .van-nav-bar__left {
        left: 0;
      }

      .van-nav-bar__right {
        right: 0;
      }

      .van-nav-bar__left, .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 var(--van-padding-md);
        font-size: var(--van-font-size-md);
      }

      .van-nav-bar__title {
        color: #000;
        font-weight: 600;
        max-width: 60%;
        margin: 0 auto;
        font-size: var(--van-nav-bar-title-font-size);
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .van-haptics-feedback {
        cursor: pointer;
      }
    }

  }

  .van-notice-bar {
    width: 94%;
    margin: 8px auto;
    background: linear-gradient(180deg, #fff, #fafafa);
    border-radius: 2rem;
    height: 34px;
    position: relative;
    display: flex;
    align-items: center;
    padding: var(--van-notice-bar-padding);
    font-size: var(--van-notice-bar-font-size);
    line-height: var(--van-notice-bar-line-height);
  }

  .my-swiper {
    margin: 0 auto 0;
    width: 94%;
    height: 10.06667rem;

    .van-swipe-item {
      height: 10.06667rem;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.3333rem;
    }

  }

  .swiper-container {
    height: 100%;
  }

  .card {
    width: 94%;
    background: repeating-linear-gradient(-60deg, #f85c9a, #f64a8e, #f85c9a, #f64a8e, #f85c9a, #f64a8e, #f85c9a, #f64a8e);
    margin: .66667rem auto;
    border-radius: .66667rem;
    padding: .66667rem;
    color: #fff;
    box-shadow: 0 0 .33333rem rgba(253, 16, 107, .51);

    .tp {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > div:first-child {
        border-right: 1PX solid #fff;
        width: 40%;

        .van-icon {
          padding: .33333rem 0;
          color: #fff;
        }
      }

      & > div:last-child {
        width: 60%;
        padding-left: 1rem;
        line-height: 1.13333rem;
        font-size: .76667rem;
        text-align: left;
      }
    }

    .ft {
      font-size: .8rem;
      padding: 1rem 1rem 0;
    }
  }

  .yixianqian {
    width: 96%;
    background: url(/img/home/tb_bgc.png) no-repeat top / 100%;
    margin: 0 auto;
    border-radius: 0 0 .66667rem .66667rem;
    overflow-x: hidden;
    padding: 0 .5rem 1rem;
    position: relative;
    overflow: hidden;

    .tk {
      color: #fff;
      padding-top: .66667rem;
      text-align: left;
      line-height: 1.26667rem;
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fe6691;
      left: 0;
      bottom: -7.33333rem;
    }

    nav {
      margin-top: 7.66667rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      z-index: 9;

      a {
        color: #ea4d4d;
        background: #fff;
        padding: .33333rem 1rem;
        margin-right: .33333rem;
        border-radius: 3.26667rem;
      }

      .active {
        background: linear-gradient(180deg, #fff2ef, #fadad7, #f7cac8);
      }
    }

    .list {
      width: 100%;
      margin-top: .66667rem;
      position: relative;
      z-index: 1;

      .slide {
        overflow: hidden;
        border: 4PX solid #fff;
        border-radius: .6rem .6rem .66667rem .66667rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 11.66667rem;
        background: #fff;
        margin-bottom: .66667rem;

        .image {
          width: 40%;
          height: 100%;
          overflow: hidden;

          img {
            width: 100%;
            display: block;
          }
        }

        .box {
          height: 100%;
          border-radius: .46667rem .46667rem 0 0;
          overflow: hidden;
          padding: .33333rem;
          flex: 1;

          .tps {
            color: #fff;
            overflow: hidden;
            background: #fff;
            padding: .33333rem .13333rem 0;

            & > p {
              color: #202441;
              font-weight: 600;
              padding: 0 0 .33333rem;
              display: flex;
              justify-content: space-between;
              align-items: center;

              em {
                color: #2e314c;
                font-size: .86667rem;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                  margin-right: .2rem;
                }
              }
            }

            .rz {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: .13333rem 0 .33333rem;

              img {
                height: 1.6rem;
                margin-right: .33333rem;
              }
            }

            span {
              font-size: .76667rem;
              margin-right: .33333rem;
              padding: .13333rem .33333rem;
              border-radius: .2rem;
              margin-bottom: .2rem;
              float: left;
            }

            span:nth-child(1n) {
              background: #f6287e;
            }

            span:nth-child(2n) {
              background: #fa9a0a;
            }

            span:nth-child(3n) {
              background: #0aa5ff;
            }


          }

          .pj {
            background: #fff;
            padding: .33333rem .33333rem;

            .wx {
              text-align: left;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: .13333rem 0;

              i {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: .26667rem;
              }
            }
          }

          a {
            width: 100%;
            display: inline-block;
            background: linear-gradient(90deg, #fa6ba6, #f32879);
            color: #fff;
            overflow: hidden;
            padding: .33333rem 0;
            font-size: .8rem;
            border-radius: 3.33333rem;
            margin: .33333rem 0;
          }
        }
      }
    }

    .gundong {
      width: 96%;
      min-height: 6.66667rem;
      background: linear-gradient(180deg, #fe5986, #ff848b);
      margin: 0 auto;
      border-radius: 1rem;
      padding-bottom: 0;

      p {
        padding: .33333rem 0;
        color: #fff;
        position: relative;
        z-index: 999;
        font-size: 1rem;
        font-weight: 600;
        width: 70%;
        margin: .66667rem auto;
        border-radius: .13333rem;
      }

      .swiper-slide {
        color: #fff;
        height: 35.8px;

      }

      div[data-v-text] {
        width: 100%;
        height: 10rem;
        overflow: hidden;
      }
    }
  }
}
</style>