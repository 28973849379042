<template>
  <div class="choose">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__title van-ellipsis">{{ $t('choose.choosing_concubine') }}</div>
        <div class="van-nav-bar__right van-haptics-feedback">
        </div>
      </div>
    </div>
    <van-tabs v-model="activeName" @change="tabsChange" ref="vanRef" @scroll="scroll">
      <van-tab v-for="(item,index) in diquList" :key="index" :name="item.id" :title="item.name">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" ref="pullRef">
          <van-list  v-model="loading"
                    :immediate-check="false"
                    :finished="finished[activeName]"
                    :finished-text="$t('setting.no_more')"
                    @load="getData">
            <div class="van-tab__panel">
              <ul class="hot">
                <li v-for="(info,index) in dataList[activeName]" :key="index" @click="toBeautys(info.id)">
                  <div class="image">
                    <img :src="info.img_url" alt="">
                    <i class="van-badge__wrapper van-icon xin" style="font-size: 32px;">
                      <img class="van-icon__image" src="/img/beauty/dianzan.png">
                    </i>
                    <div>
                      <img
                          alt=""
                          src="/img/beauty/tongcheng_bgc.png"
                      />
                      <span>{{ info.is_kj === 1 ? $t('choose.airborne') : $t('choose.same_city') }}</span>
                    </div>
                  </div>
                  <div class="cover">
                    <div>
                      <div class="van-image van-image--round" style="width: 28px; height: 28px;"><img
                          :src="info.img_url" class="van-image__img"
                          style="object-fit: cover;"></div>
                      <span class="name">{{ info.xuanfei_name }} </span></div>
                    <div><i class="van-badge__wrapper van-icon"><img
                        class="van-icon__image"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAAAAXNSR0IArs4c6QAAAuhJREFUSEullctPE2EUxc+dFgQfKYix7bQbH2Cn1bggJDZxJcxgYkwMC0NidOXKsJFESRQFUYyQyI5/wGhiwsKN0cDQRFcSd8bQqSC6wD5AJLLwAWXmmhYKBWaGqXQ1nXvO73z3m+/OEGx+B47JYhmhFSAZ4BMgqsrLmX8C9BFgNct4PvdJTVlhyKzgPaIcFMrxAMAVALvsFgFgEcATYwmdM1Mjs5u1WwK8dXIjuegZAd5twBvKDMyAjdZMYvRNcWFDgD/U1AIIQyAIpcALWgJnDR0XMhPqq/V7q1eBuuaTLBhjIKr4H/iah/kPGUI0OTH8IXdvpYP6+jLxd804gNodwQtmRiKV8BwHhvR8gF9SrhMwYAOfNkCdWT0bE9wVupuzUQL1Azhq7eG2lKYO5gLIH1KmiHDIQjy9/FdvmP0amymuB0KNNUzCO4Csuv6c0kZqyVfX2CC4XO+tVsLEl9Nx9alZ3Sc1nRMgvLTyGlk9Qj5JuSYAg1aiJZA4pw2nzerBYLTS2Ld3AaAyC/9VEkNKFwjdVgH6ErxmA5TTi+L53Vy1uEAMt6mfjW4Sw0oXYB3AzC1pTX1hBvBHGs8Su17bHI7tAwBMkqFHk4nYj2JQ9eEmT2WFMAYgZBvgD8ntJNDjbc7/JBloX+Rfb3M6t2vPaWHlWNvBcy/FNvJGlDMuRszJgDGwvDqd5nu+CaIDpwjhcLmIYAZAtZMQpxpmfE9rHn9+kn2S3CcQ3XRqdqIzmPszmtqRDwhGmvezwV9A8DgxO9DME1Htt/Hh+bXXtT+sXBIA04l1ANwk4YvJuDq0+rzWa6Ik99FOt4qNzqQ22lugbvmiBSRlAITrpa8aYKA7FR+5V+w1/SaLktJPhBulhJjBt2xRMbCU7WLirtS42mO2INMOCkJRkh8RUYddJ3Zw2w4chTDfTWrqfbsF2HZQMAbCci9AtzaAHMAddVDUSQ8R3cn/dwgvKSAnDoSVhzl6Mq7ednrC/gEPG/zSvQzqtgAAAABJRU5ErkJggg==">
                    </i><span class="dingwei">{{ info.address }}</span></div>
                  </div>
                  <div class="nav">
                    <span v-for="(tab,idx) in info.tag_name" :key="idx">{{ tab }}</span>
                  </div>
                  <div class="sf">
                <span>
                  <i v-for="e in  Array.from({ length: Math.floor(info.rating) })" :key="e"
                     class="van-badge__wrapper van-icon" style="font-size: 18px;">
                    <img class="van-icon__image" src="/img/beauty/money.png">
                 </i>
                </span>
                  </div>
                  <div class="b"  ><a style="overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">{{ $t('home.i_want_to_date_her') }}&gt;&gt;&gt;</a>
                  </div>
                </li>

              </ul>
            </div>

          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 0,
      dataList: {},
      count: {},
      diquList: [],
      page: {},
      loading: false,
      finished: {},
      refreshing: false,

    };
  },
  methods: {
    getData(id = this.activeName) {
      if (this.refreshing) {
        this.dataList[id] = [];
        this.refreshing = false;
        this.page[id] = 1
      }
      if (!this.page[id]) {
        this.page[id] = 1
      }
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: {
          class_id: id ? id : null,
          page: this.page[id],
        },
      }).then(res => {
        if (this.dataList[this.activeName]) {
          this.dataList[this.activeName] = [...this.dataList[this.activeName], ...res.data.data]
        } else {
          this.dataList[this.activeName] = res.data.data
        }
        this.loading = false;
        this.page[id]++
        this.count[this.activeName] = res.data.count
        if (this.dataList[this.activeName].length >= res.data.count) {
          this.finished[this.activeName] = true;
        }
        this.$forceUpdate()
      })
    },
    initData() {
      this.$http({
        method: 'get',
        url: 'address_list',
      }).then(result => {
        this.diquList = [{id: 0, name: this.$t('setting.all')}, ...result.data]
        this.activeName = 0
        this.page[this.activeName] = 1
        this.getData()
      })
    },
    onRefresh() {
      this.finished[this.activeName] = false;
      this.loading = true;
      this.getData();
    },
    toBeautys(id) {
      this.$router.push('/beautys?id=' + id)
    },
    tabsChange(index) {
      this.activeName = index
      if (!this.dataList[index]) {
        this.getData(index)
      }
    },
    scroll(e) {
      let scrollTop = e.target.scrollTop
      localStorage.setItem('scrollTop', scrollTop);
    }
  },
  mounted() {
    this.initData()
  },
  activated () {
    const scrollTop = localStorage.getItem('scrollTop');
    if (scrollTop) {
      document.getElementById('app').scrollTo(0, parseInt(scrollTop));
    }
    document.getElementById('app').addEventListener('scroll', this.scroll)

  },
  deactivated () {
    document.getElementById('app').removeEventListener('scroll', this.scroll)
  },
}
</script>

<style lang='less' scoped>
/deep/ .van-tabs__line {
  display: none;
}

/deep/ .van-loading {
  line-height: 50px;
}

/deep/ .van-tabs__content {
  padding-bottom: 68px;
  position: relative;

  .load-trigger {
    height: 100px;
    position: absolute;
    bottom: 500px;
    width: 100%;
  }
}

.van-tab__panel {

  .hot {
    width: 96%;
    margin: 0 auto;
    padding-top: .33333rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    li {
      width: 49%;
      background: #fff;
      padding: .4rem;
      border-radius: .33333rem;
      margin-bottom: .66667rem;
      box-shadow: 0 .33333rem .66667rem rgba(255, 0, 110, .11);

      .image {
        width: 100%;
        height: 10rem;
        border-radius: .26667rem;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
        }

        i {
          position: absolute;
          top: .33333rem;
          left: .33333rem;
        }

        & > div {
          position: absolute;
          right: 0;
          z-index: 1;
          top: 0;

          img {
            width: 2.66667rem;
          }

          span {
            position: absolute;
            top: .13333rem;
            right: .33333rem;
            font-size: .76667rem;
            color: #fff;
          }
        }
      }

      .cover {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .33333rem 0;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            width: 4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .8rem;
            text-align: left;
            margin: .16667rem .16667rem;
          }

          .dingwei {
            margin: .16667rem .16667rem;
            font-size: .8rem;
          }

          .van-icon {
            font-size: 16px;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }

      }

      .nav {
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-size: .73333rem;
          color: #fff;
          padding: .13333rem .26667rem;
          border-radius: .2rem;
          margin-right: .2rem;
          margin-bottom: .26667rem;
        }

        span:nth-child(1n) {
          background: #512ed1;
        }

        span:nth-child(2n) {
          background: #fa9a0a;
        }

        span:nth-child(3n) {
          background: #0aa5ff;
        }

        span:nth-child(4n) {
          background: #01bcaf;
        }
      }

      .sf, .sf span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .sf, .b {
        padding: .33333rem 0;

        a {
          background: linear-gradient(90deg, #fc78af, #f22678);
          color: #fff;
          padding: .33333rem 1rem;
          border-radius: 3.33333rem;
          font-size: .8rem;
        }
      }


    }
  }

}


/deep/ .van-tabs__nav {
  background: none !important;
  padding-right: var(--van-padding-xs);
  padding-left: var(--van-padding-xs);
  box-sizing: content-box !important;
  height: 100%;
  padding-bottom: 15px;


  .van-tab {
    padding: 0 5px;
    color: rgb(47, 55, 70);
  }

  .van-tab__text {
    background: #fff;
    padding: 4px 14px;
    border-radius: 90px;
    font-weight: 400;
  }

  .van-tab--active .van-tab__text {
    background: linear-gradient(180deg, #fc78af, #f22678);
    color: #fff;
    box-shadow: 0 2px 6px rgba(242, 38, 120, .25);
  }

}

.choose {
  width: 100%;
  min-height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-nav-bar {
    z-index: 999;
    background: none;
    position: relative;
    line-height: var(--van-line-height-lg);
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .van-nav-bar__content {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--van-nav-bar-height);

      .van-nav-bar__title {
        color: #000;
        font-weight: 600;
        max-width: 60%;
        margin: 0 auto;
        font-size: var(--van-nav-bar-title-font-size);
        line-height: var(--van-line-height-lg);
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 var(--van-padding-md);
        font-size: var(--van-font-size-md);
      }

      .van-nav-bar__right {
        right: 0;
      }
    }

    .van-hairline--bottom {
      border-width: 0;
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 0 solid var(--van-border-color);
      transform: scale(.5);
    }
  }

  .van-tab--grow {
    padding: 0 5px;
  }

}
</style>