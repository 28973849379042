<template>
  <div class="lottery" :style="{display: isLoad ? 'block' : 'none'}">
    <div class="tops">
      <van-nav-bar
          :title="name"
          left-arrow
          @click-left="back"
      />
      <div class="top">
        <div class="qs">
          <div>
            <h1>{{ next_expect }}{{ $t('setting.period') }}</h1>
          </div>
          <span>
            <span>{{ $t('game.end_of_voting') }}</span><van-count-down :time="second" @finish="finish" ref="countDown" />
          </span>
        </div>
        <div class="number">
          <div class="left">
            <div class="ball"><p>{{ list[0] ? list[0].expect : '' }}<br>{{ $t('game.period_result') }}</p>
            </div>
          </div>
          <div class="right">
            <div class="res">
              <div>
                <span>{{ list[0] ? list[0].opencode.reduce((a, b) => a + b) : '' }}</span>
                <span>{{ list[0] ? list[0].opencode.reduce((a, b) => a + b) > 10 ? $t('game.rocket') : $t('game.airplane') :''}}</span>
                <span>{{ list[0] ? list[0].opencode.reduce((a, b) => a + b) % 2 === 0 ? $t('game.carriage') : $t('game.cruise_ship') :'' }}</span>
              </div>
              <div>
                <span>{{ list[0] ? list[0].opencode[0] : '' }}</span>
                <span>{{ list[0] ? list[0].opencode[1] : '' }}</span>
                <span>{{ list[0] ? list[0].opencode[2] : '' }}</span>
              </div>
            </div>
            <i :style="{color: 'rgb(255, 255, 255)', transition: '0.2s', transform: isShow ? 'rotate(180deg)': 'rotate(0deg)'}"
               class="van-badge__wrapper van-icon van-icon-arrow-down"
               @click="clickShow">
            </i>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div v-if="isShow" class="history">
      <div class="animate__animated animate__fadeInDown">
        <div class="item">
          <div class="left">{{ $t('game.period') }}</div>
          <div class="right">{{ $t('game.lottery_number') }}</div>
        </div>
        <div v-for="(item,index) in list" :key="index" class="item">
          <div class="left">{{ item.expect }}</div>
          <div class="right">
            <div>
              <span v-for="(num,index) in item.opencode" :key="index">{{ num }}</span>
            </div>
            <div>
              <span>{{ item.opencode.reduce((a, b) => a + b) }}</span>
              <span>{{ item.opencode.reduce((a, b) => a + b) > 10 ? $t('game.rocket') : $t('game.airplane') }}</span>
              <span>{{
                  item.opencode.reduce((a, b) => a + b) % 2 === 0 ? $t('game.carriage') : $t('game.cruise_ship')
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lottery-content">
      <div class="left">
        <div class="item"><span>和值</span>
          <div class="active slide">两面</div>
          <div class="slide" style="display: none;">和值</div>
        </div>
        <div class="item">
          <div class="slide" style="display: none;">复选</div>
          <div class="slide" style="display: none;">标准选号</div>
        </div>
        <div class="item">
          <div class="slide" style="display: none;">标准选号</div>
        </div>
        <div class="item">
          <div class="slide" style="display: none;">通选</div>
        </div>
      </div>
      <div id="toBottom" class="right">
        <div id="eidet" class="ball" style="padding-bottom: 60px; transition: 0.2s;">
          <div>
            <div v-for="(item,index) in typeList" :key="index"
                 :class="selectList.includes(index) ? 'idx0 active' :'idx0'" @click="selectEidet(index)">
              <p>{{ item.name }}</p>
              <span>(2.00)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectList.length" class="mask animate__animated animate__fadeInUp">
      <div class="van-badge__wrapper badge">
        <i class="van-badge__wrapper van-icon">
          <img class="van-icon__image" src="/img/game/order.png"/>
        </i>
        <div class="van-badge van-badge--top-right van-badge--fixed">2</div>
      </div>
      <div class="mdjf"><p>{{ $t('game.points_per_order') }}：</p><input v-model="price" type="number">
      </div>
      <div class="zj">
        <p>{{ $t('game.total') }}<span>{{ selectList.length }}</span>{{ $t('game.Single') }}</p>
        <p>{{ $t('game.total') }}<span>{{ selectList.length * price }}</span>{{ $t('game.point') }}</p>
      </div>
      <a class="sub" @click="show = true">{{ $t('game.submit') }}</a></div>
    <van-popup v-model="show" class="order">
      <div class="title">
        <span></span>
        <span>{{ $t('game.task_order') }}</span>
        <van-icon name="cross" @click="show=false"></van-icon>
      </div>
      <div v-for="item in selectList" :key="item" class="content">
        <div>
          <span>{{ typeList[item].name }}</span>
          <p>1 {{ $t('game.single') }} X{{ Number(price).toFixed(2) }}({{ $t('game.points') }})={{
              Number(price).toFixed(2)
            }}({{ $t('game.points') }})</p>
        </div>
        <span @click="delCurrent(item)"></span>
      </div>
      <div class="btn">
        <a @click="clearOrder">{{ $t('game.clear_order') }}</a>
        <a @click="submit">{{ $t('game.confirm_submission') }}</a>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      key: 0,
      id: 0,
      list: [],
      selectList: [],
      next_expect: '',
      name: '',
      typeList: [],
      price: 10,
      show: false,
      second: 0,
      isLoad: false
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    clickShow() {
      this.isShow = !this.isShow
    },
    selectEidet(index) {
      if (this.selectList.indexOf(index) === -1) {
        this.selectList.push(index)
      } else {
        this.selectList.splice(this.selectList.indexOf(index), 1)
      }
    },
    delCurrent(e) {
      this.selectList.splice(this.selectList.indexOf(e), 1)
      if (this.selectList.length === 0) {
        this.show = false
      }
    },
    clearOrder() {
      this.show = false
      this.selectList = []
    },
    submit() {
      this.$http({
        method: 'post',
        url: 'game_place_order',
        data: {
          lid: this.id,
          money: this.price,
          item: this.selectList.map((item) => this.typeList[item].type).join(',')
        },
        isLoading: true
      }).then(res => {
        if (res.code === 200) {
          this.show = false
          this.selectList = []
          this.$toast(res.msg)
        } else {
          this.$toast(res.msg)
        }
      })
    },
    finish() {
      this.$http({
        method: 'get',
        url: 'lottery_get_one_list',
        data: {
          key: this.key
        }
      }).then(res => {
        this.list = res.data
      })
      this.$http({
        method: 'get',
        url: 'lottery_get_info',
        data: {
          key: this.key
        }
      }).then(res => {
        if(this.next_expect) {
          this.next_expect = res.data.next_expect
          this.$toast(this.next_expect+this.$t('setting.period')+this.$t('setting.updated'))
        } else if(res.code === 505) {
          this.$toast.fail(this.$t('setting.network_error'))
        } else {
          this.name = res.data.name
          this.next_expect = res.data.next_expect
          this.isLoad = true
        }
        this.second = res.data.second * 1000
        this.$refs.countDown.reset()
      })
    }
  },
  mounted() {
    this.key = this.$route.query.key
    this.id = this.$route.query.id
    this.$http({
      method: 'get',
      url: 'lottery_get_peilv',
      data: {
        id: this.id
      }
    }).then(res => {
      this.typeList = res.data
    })
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-count-down {
  color: #20d917;
  font-size: 1rem;
  height: 18px;
}

/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}

/deep/ .van-nav-bar {
  background: none;

  .van-icon {
    color: #fff;
    font-size: 20px;
  }

  .van-nav-bar__title {
    font-weight: 600;
    color: #fff;
  }
}

.lottery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url(/img/index_bg.png) repeat top / 100% auto;
  position: absolute;

  .history {
    position: absolute;
    width: 100%;
    height: calc(100% - 10.33333rem);
    background: rgba(0, 0, 0, .2);
    z-index: 1;
    left: 0;
    right: 0;
    top: 10.33333rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .animate__animated {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-duration: var(--animate-duration);
      animation-duration: var(--animate-duration);
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }

    .animate__fadeInDown {
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
    }

    & > div {
      overflow-y: auto;
      width: 100%;
      height: calc(100% - 4.33333rem);
      background: #fff;

      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f3f3f3;
        height: 3rem;

        &:nth-child(2n) {
          background: #fff;
        }

        & > div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #404040;
          font-size: .8rem;
        }


        .left {
          width: 30%;
          text-align: center;
          border-right: 1px solid #ebe8e8;
        }

        .right {
          flex: 1;
          text-align: center;

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child span {
              border: 1px solid #606060;
              width: 1.33333rem;
              height: 1.33333rem;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #606060;
              border-radius: .2rem;
              margin-right: .33333rem;
              font-weight: 600;
            }

            &:last-child span {
              width: 1.66667rem;
              height: 1.66667rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(180deg, #fc78af, #f22678);
              color: #fff;
              border-radius: 50%;
              font-size: .76667rem;
              margin-right: .33333rem;

              &:nth-child(2), &:nth-child(3) {
                width: 72PX;
                padding: 0 .33333rem;
                border-radius: 50PX;
                white-space: nowrap;
              }
            }
          }
        }

        &:first-child > div {
          font-size: .93333rem;
        }
      }
    }
  }

  .tops {
    height: 10.33333rem;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
    background: url(/img/game/detail_bg.png) no-repeat top / 100%;
  }

  .top {
    width: 100%;
    padding: .33333rem .66667rem .66667rem;
    position: relative;
    z-index: 10;

    .qs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .46667rem;

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h1 {
          font-size: .96667rem;
          color: #fff;
          padding-left: .66667rem;
          font-weight: 400;
        }
      }

      & > span {
        color: #20d917;
        font-size: 1rem;
        display: flex;
        align-items: center;

        & > span {
          color: #fff;
          font-size: .93333rem;
          margin-right: .66667rem;


        }
      }
    }

    .number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: .26667rem;
      padding: .5rem .66667rem;
      background: url(/img/game/qihao_bg.png) no-repeat 50% / 100%;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ball {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 1.8rem;
            width: 6.93333rem;
            font-size: 1rem;
            color: #fff;
            font-weight: 600;
          }
        }
      }

      .right {
        width: 60%;
        padding: 0 .66667rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: .26667rem;

        &:before {
          content: "";
          position: absolute;
          left: -1.66667rem;
          width: 1PX;
          height: 2.66667rem;
          background: hsla(0, 0%, 100%, .43);
        }

        .res {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              width: 1.86667rem;
              height: 1.86667rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-size: .76667rem;
              color: #fff;
              margin-right: .33333rem;
              background: linear-gradient(180deg, #fc78af, #f22678);

              &:nth-child(2), &:nth-child(3) {
                width: auto;
                padding: 0 .33333rem;
                border-radius: 52PX;
                white-space: nowrap;
              }
            }
          }

          & > :last-child {
            margin-top: .33333rem;

            span {
              background: none;
              border: 1px solid #fff;
              border-radius: .13333rem !important;
              width: 1.66667rem !important;
              height: 1.66667rem;
              font-size: .83333rem;
            }
          }

        }
      }
    }
  }

  .lottery-content {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;

    .left {
      width: 6.06667rem;
      height: 100%;
      background: #fff;
      padding-top: 1rem;
      border-top: 1rem solid #f0f0f0;
      display: none;

      .item {
        width: 100%;

        span {
          width: 100%;
          background: #f3cb76;
          color: #fff;
          display: inline-block;
          font-size: .83333rem;
          padding: .66667rem 0;
        }

        &:first-child span {
          border-radius: 0 .33333rem 0 0;
        }


        & > .slide {
          padding: 1rem 0;
          background: #fff;
        }

        & > .slide.active {
          background: #ece6eb;
          color: #7e5678;
          position: relative;
          overflow: hidden;
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      overflow-y: auto;

      .ball {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding: 5% 5% 4rem;
        position: relative;

        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .active {
            border: .33333rem solid #f8596f;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: .33333rem;
              background: url(/img/game/select.png) no-repeat 101% 100% / 1.33333rem;
            }
          }

          .idx0 {
            width: 48%;
            height: 6.66667rem;
            background: #fff;
            border-radius: .33333rem;
            margin-bottom: 5%;
            box-shadow: 0 0 .33333rem rgba(0, 0, 0, .1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            &:first-child {
              background: url(/img/game/huojian.jpg) no-repeat 50% 50% / 110% auto, #fff;
            }

            &:nth-child(2) {
              background: url(/img/game/feiji.jpg) no-repeat 50% 0 / 110% auto, #fff;
            }

            &:nth-child(3) {
              background: url(/img/game/youlun.jpg) no-repeat 50% 80% / 110% auto, #fff;
            }

            &:nth-child(4) {
              background: url(/img/game/mache.jpg) no-repeat 50% 80% / 100% auto, #fff;
            }

            p {
              color: transparent;
              font-size: 1.16667rem;
              font-weight: 600;
            }

            span {
              color: #ff253f;
              font-size: .76667rem;
              padding-top: .66667rem;
              display: none;
            }
          }
        }
      }
    }
  }

  .mask {
    position: absolute;
    bottom: 0;
    height: 3.66667rem;
    width: 100%;
    background: #3a3635;
    box-shadow: 0 0 .33333rem rgba(190, 16, 16, .07);
    border-radius: 3.33333rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4.33333rem 0 4.33333rem;
    color: #fff;

    .badge {
      position: absolute;
      left: 0;
      display: inline-block;

      & > i {
        width: 3.66667rem;
        height: 3.66667rem;
        background: #312e2d;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1.66667rem;
      }

      .van-badge--top-right {
        transform: translateY(-50%);
        font-size: 14px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

      }
    }

    .mdjf {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      input {
        width: 3.66667rem;
        height: 1.66667rem;
        color: #f6287e;
        text-align: center;
        border-radius: .26667rem;
        border: none;
        outline: none;
        font-weight: 600;
      }
    }

    .zj {
      span {
        color: #ff378c;
        margin: 0 .13333rem;
      }
    }

    .sub {
      position: absolute;
      right: 0;
      width: 3.66667rem;
      background: #f6287f;
      color: #fff;
      font-size: .93333rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 50% 50% 0;
    }
  }

  & > .animate__fadeInUp {
    --animate-duration: .5s;
    -webkit-animation-duration: 1s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
}

.order {
  width: 80%;
  border-radius: .33333rem;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 5%;
    border-bottom: 1px solid #f7f8f9;
    background: linear-gradient(133deg, #e64588, #f22678);

    span {
      color: #fff;
      font-size: 1.03333rem;
    }

    i {
      font-size: 1.33333rem;
      color: #fff;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: .66667rem 5%;
    border-bottom: 1px solid #f7f8f9;

    & > div {
      text-align: left;

      span {
        color: #f6379c;
        font-size: .8rem;
        text-align: left;
        display: inline-block;
        padding: .33333rem 0;

      }

      p {
        color: #5739ab;
        font-size: .86667rem;
      }
    }

    & > span {
      display: flex;
      width: 1.2rem;
      height: 1.33333rem;
      background: #f6379c;
      border-radius: .2rem;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 90%;
        height: .13333rem;
        background: #fff;
        margin: auto;
      }
    }
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50% 5% 5%;

    a {
      border: 1px solid #979799;
      color: #979799;
      font-size: .8rem;
      padding: .53333rem 1.66667rem;
      border-radius: 3.33333rem;

      &:last-child {
        background: linear-gradient(90deg, #fa6ba6, #f32879);
        color: #fff;
        border: none;
      }
    }
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
</style>