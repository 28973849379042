<template>
  <van-nav-bar
      ref="navBarRef"
      :style="{background:background,'z-index':zIndex}"
      :title="title"
      class="header"
      left-arrow
      @click-left="goBack"
  >
    <template #right>
      <slot></slot>
    </template>
  </van-nav-bar>
</template>
<script>
export default {
  props: {
    title: String,
    background: {
      type: String,
      default: '#fff'
    },
    zIndex: {
      type: String,
      default: '3000'
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  box-shadow: 0 0 .16667rem rgba(0, 0, 0, .08);
}

/deep/ .van-nav-bar__content {
  background: transparent;

  .van-icon {
    color: #000;
    font-size: 20px;
  }

  .van-nav-bar__title {
    font-weight: 600;
    color: #000;
  }

}

</style>