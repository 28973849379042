<template>
  <div class="movie">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__title van-ellipsis">{{ $t('video.theater') }}</div>
        <div class="van-nav-bar__right van-haptics-feedback">
          <i class="van-badge__wrapper van-icon" style="font-size: 28px;">
            <van-badge :content="messageCount" v-if="messageCount > 0">
              <img class="van-icon__image" src="/img/home/info.png" @click="goNotion">
            </van-badge>
            <img v-else class="van-icon__image" src="/img/home/info.png" @click="goNotion">
          </i>
        </div>
      </div>
    </div>
    <van-tabs v-model="activeName" @change="tabsChange">
      <van-tab v-for="(item,index) in tabsList" :key="index" :name="item.id" :title="item.name">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              :immediate-check="false"
              v-model="loading"
              :finished="finished[activeName]"
              :finished-text="$t('setting.no_more')"
              @load="getData"
          >
            <div class="van-tab__panel">
              <ul class="hot">
                <li v-for="(video,index) in dataList[activeName]" :key="index" @click="goDetail(video)">
                  <img :src="video.vod_pic"
                       alt="">
                  <div>
                    <span>{{ video.vod_name }}</span>
                    <span>{{ $t('video.play') }}:{{ video.playsNum }}
                  <i class="van-badge__wrapper van-icon"
                     style="font-size: 12px; margin-left: 10px;">
                    <img class="van-icon__image" src="/img/movie/dianzan.png"></i> {{ video.likesNum }}%</span>
                  </div>
                </li>
              </ul>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeName: 0,
      dataList: {},
      count: {},
      tabsList: [],
      page: {},
      loading: false,
      finished: {},
      refreshing: false,
      messageCount: 0,
      timer: null
    };
  },
  methods: {
    onRefresh() {
      this.finished[this.activeName] = false;
      this.loading = true;
      this.getData();
    },
    goNotion() {
      this.$router.push({path: "/notion", query: {type: 1}})
    },
    goDetail(item) {
      const str = this.$objToString(item)
      this.$router.push("/moviedetail?" + str)
    },
    getPlaysNumber() {
      return Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
    },
    getLikesNumber() {
      return Math.floor(Math.random() * (100 - 80 + 1)) + 80
    },
    tabsChange(index) {
      this.activeName = index
      if (!this.dataList[index]) {
        this.getData(index)
      }
    },
    getData(id = this.activeName) {
      if (this.refreshing) {
        this.dataList[id] = [];
        this.refreshing = false;
        this.page[id] = 1
      }
      if (!this.page[id]) {
        this.page[id] = 1
      }
      this.$http({
        method: 'get',
        url: 'video_list',
        data: {
          id,
          page: this.page[id],
        },
        isLoading: this.page[id] === 1
      }).then(res => {
        if (this.dataList[this.activeName]) {
          this.dataList[this.activeName] = [...this.dataList[this.activeName], ...res.data.data.map(info => {
            info.playsNum = this.getPlaysNumber()
            info.likesNum = this.getLikesNumber()
            return info
          })]
        } else {
          this.dataList[this.activeName] = res.data.data.map(info => {
            info.playsNum = this.getPlaysNumber()
            info.likesNum = this.getLikesNumber()
            return info
          })
        }
        this.loading = false;
        this.page[id]++
        this.count[this.activeName] = res.data.count
        if (this.dataList[this.activeName].length >= res.data.count) {
          this.finished[this.activeName] = true;
        }
        this.$forceUpdate()
      })
    },
    initData() {
      this.$http({
        method: 'get',
        url: 'video_class',
      }).then(result => {
        this.tabsList = result.data
        this.activeName = result.data[0].id
        this.page[this.activeName] = 1
        this.getData()
      })
    },
    scroll(e) {
      let scrollTop = e.target.scrollTop
      localStorage.setItem('movieScrollTop', scrollTop);
    },
    getNotion() {
      if (localStorage.getItem('token')) {
        this.$http({
          method: 'get',
          url: 'user_info',
        }).then(res => {
          if (res.code === 200) {
            this.messageCount = res.data['message_count']
            localStorage.setItem('userInfo', JSON.stringify(res.data))
          }
        })
        this.timer = setInterval(()=>{
          this.$http({
            method: 'get',
            url: 'user_info',
          }).then(res => {
            if (res.code === 200) {
              this.messageCount = res.data['message_count']
              localStorage.setItem('userInfo', JSON.stringify(res.data))
            }
          })
        },5000)
      }
    }
  },
  activated () {
    const scrollTop = localStorage.getItem('movieScrollTop');
    if (scrollTop) {
      document.getElementById('app').scrollTo(0, parseInt(scrollTop));
    }
    document.getElementById('app').addEventListener('scroll', this.scroll)

  },
  deactivated () {
    document.getElementById('app').removeEventListener('scroll', this.scroll)
  },
  mounted() {
    this.getNotion()
    this.initData()
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style lang='less' scoped>
/deep/ .van-badge {
  border: none;
}
/deep/ .van-tabs__line {
  display: none;
}

/deep/ .van-loading {
  line-height: 50px;
}

/deep/ .van-tabs__content {
  padding-bottom: 68px;
  position: relative;

  .load-trigger {
    height: 100px;
    position: absolute;
    bottom: 500px;
    z-index: -1;
    width: 100%;
  }
}

.van-tab__panel {
  padding: 0 10px 0px;

  .hot {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;

    li {
      width: calc(50% - .2rem);
      border-radius: .2rem;
      overflow: hidden;
      margin-top: .46667rem;
      height: 10rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
      }

      & > div {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(2, 5, 16, .6);
        padding: .13333rem 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        span {
          color: #fff;
          font-size: .73333rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 .33333rem;
          text-align: left;
        }
      }
    }
  }

}


/deep/ .van-tabs__nav {
  background: none !important;
  padding-right: var(--van-padding-xs);
  padding-left: var(--van-padding-xs);
  box-sizing: content-box !important;
  height: 100%;
  padding-bottom: 15px;


  .van-tab {
    padding: 0 5px;
    color: rgb(47, 55, 70);
  }

  .van-tab__text {
    background: #fff;
    padding: 4px 14px;
    border-radius: 90px;
    font-weight: 400;
  }

  .van-tab--active .van-tab__text {
    background: linear-gradient(180deg, #fc78af, #f22678);
    color: #fff;
    box-shadow: 0 2px 6px rgba(242, 38, 120, .25);
  }

}

.movie {
  width: 100%;
  min-height: 100%;
  background: url(/img/index_bg.png) repeat top / 100% auto;

  .van-nav-bar {
    z-index: 999;
    background: none;
    position: relative;
    line-height: var(--van-line-height-lg);
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .van-nav-bar__content {
      position: relative;
      display: flex;
      align-items: center;
      height: var(--van-nav-bar-height);

      .van-nav-bar__title {
        color: #000;
        font-weight: 600;
        max-width: 60%;
        margin: 0 auto;
        font-size: var(--van-nav-bar-title-font-size);
        line-height: var(--van-line-height-lg);
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 var(--van-padding-md);
        font-size: var(--van-font-size-md);
      }

      .van-nav-bar__right {
        right: 0;
      }
    }

    .van-hairline--bottom {
      border-width: 0;
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border: 0 solid var(--van-border-color);
      transform: scale(.5);
    }
  }

  .van-tab--grow {
    padding: 0 5px;
  }

}
</style>